import React, { useState, useEffect } from 'react';
import MailchimpSubscribe from 'react-mailchimp-subscribe';
import { useTranslation } from 'react-i18next';
import { Link } from 'gatsby';
import { globalHistory } from '@reach/router';
import { notification } from 'antd';
import axios from 'axios';
import css from './style.module.scss';

import arrowRightSmallIcon from '../../../../assets/icons/arrow-right-small.svg';
import emailIcon from '../../../../assets/icons/subscribe-email.svg';
import greenCheckImg from '../../../../assets/images/green-check.svg';
import comingSoonImg from '../../../../assets/images/coming-soon.svg';

interface Item {
  [key: string]: string;
}
interface Request {
  interests: any;
  email: string;
  status: boolean;
}

const ComingSoon: React.FC = () => {
  const { t } = useTranslation();

  const [complete, setComplete] = useState(false);

  const [request, setRequest] = useState<Request>({ interests: {}, email: '', status: false });

  useEffect(() => {
    setRequest({ ...request, interests: { [process.env.MC_INTEREST_ID]: true } });
  }, [globalHistory.location.pathname]);

  const openNotification = () => {
    notification.open({
      message: t('shell:projects.comingSoon.subscribingError'),
      description: t('shell:projects.comingSoon.subscribeErrorMessage'),
    });
  };

  const handelClick = () => {
    const body = {
      email_address: request.email,
      interests: request.interests,
    };

    const subscribe = () => {
      axios
        .put(`${process.env.SUBSCRIBE_API}`, body)
        .then(() => setComplete(true))
        .catch(() => openNotification());
    };

    const regExp = /([A-Za-z0-9_\-\.])\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;

    return regExp.test(request.email) ? subscribe() : openNotification();
  };

  const listOfBtnTitles = [
    { name: t('shell:projects.comingSoon.recordMenu1'), url: '/slimming-school' },
    { name: t('shell:projects.comingSoon.recordMenu2'), url: '/fitness-equipment' },
    { name: t('shell:projects.comingSoon.recordMenu3'), url: '/face-fitness' },
    { name: t('shell:projects.comingSoon.recordMenu4'), url: '/slimming-school' },
    { name: t('shell:projects.comingSoon.recordMenu5'), url: '/' },
  ];

  if (complete) {
    return (
      <div className={css.container}>
        <img className={css.checkIcon} src={greenCheckImg} alt="img" />
        <div className={css.subtitle}>
          <p className={css.bold}>{t('shell:projects.comingSoon.recordComplete')}</p>
          <p>{t('shell:projects.comingSoon.recordCompleteSubtitle')}</p>
        </div>
        <p className={css.regularText}>А пока можете посмотреть, что интересного есть у нас сейчас:</p>
        {listOfBtnTitles.map((item: Item, index) => {
          return (
            <Link to={item.url} key={`${index + 1}BTN`}>
              <button type="button" className={css.linkButton}>
                <span className={css.icon}>
                  <img src={arrowRightSmallIcon} alt="img" />
                </span>
                {item.name}
              </button>
            </Link>
          );
        })}
      </div>
    );
  }
  return (
    <div className={css.container}>
      <h1 className={css.title}>{t('shell:projects.comingSoon.header')}</h1>
      <img className={css.img} src={comingSoonImg} alt="img" />
      <div className={css.subtitle}>
        <p className={css.bold}>{t('shell:projects.comingSoon.subscribe')}</p>
        <p>{t('shell:projects.comingSoon.subscribeSubtitle')}</p>
      </div>
      <div className={css.form}>
        <div className={css.inputWrapper}>
          <input
            type="text"
            className={css.input}
            placeholder={t('shell:projects.comingSoon.subscribeInput')}
            onChange={e => setRequest({ ...request, email: e.target.value })}
          />
        </div>
        <button type="button" className={css.button} onClick={handelClick}>
          {t('shell:projects.comingSoon.subscribeBtn')}
          <img className={css.icon} src={emailIcon} alt="img" />
        </button>
      </div>
    </div>
  );
};

export default ComingSoon;
