import React from 'react';
import { Form, Icon as LegacyIcon } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Radio } from 'antd';
import { FormComponentProps } from '@ant-design/compatible/lib/form';
import { TFunction } from 'i18next';
import { FulfillmentRateCalculateResponseDto, PayformCurrency } from '../../../../graphql-types';
import { RadioChangeEvent } from 'antd/lib/radio';
import { ShippingMethod, DeliveryType } from '../../../../lib/common-interfaces';
import { useDispatch } from 'react-redux';
import { setSelectedShippingActionCreator } from '../../../../redux/shipping/shipping.actions';
import { Rates } from '../../../../lib/hooks/rates';
import { PayformCurrency as constPayformCurrency } from '../../../../lib/constants';

import css from '../checkoutform.module.scss';

interface Props extends FormComponentProps {
  t: TFunction;
  calculateFulFillmentRateData: FulfillmentRateCalculateResponseDto;
  validateStep: (step: number) => void;
  convert: (price: number, payformCurrency: PayformCurrency, currency: PayformCurrency) => number;
  rates?: Rates;
  currency: PayformCurrency;
  setStep: (value: number) => void;
}

export const CheckoutFormStep2: React.FC<Props> = ({
  form,
  t,
  calculateFulFillmentRateData,
  validateStep,
  convert,
  rates,
  currency,
  setStep,
}) => {
  const { getFieldDecorator } = form;
  const dispatch = useDispatch();

  const getIcon = (shippingType: DeliveryType): JSX.Element => {
    let iconType: string;
    switch (shippingType) {
      case DeliveryType.COMMON:
        iconType = 'compass';
        break;
      case DeliveryType.FAST:
        iconType = 'dashboard';
        break;
      case DeliveryType.EXPRESS:
        iconType = 'fire';
        break;
      case DeliveryType.SUPER_EXPRESS:
        iconType = 'rocket';
        break;
      default:
        iconType = 'compass';
    }
    return <LegacyIcon type={iconType} className={css.icon} />;
  };

  return (
    <>
      {calculateFulFillmentRateData && !calculateFulFillmentRateData.methods && !form.getFieldValue('country') && (
        <span className={css.shippingWarning}>{t('checkout:shipping.selectCountry')}</span>
      )}
      {calculateFulFillmentRateData &&
        calculateFulFillmentRateData.methods &&
        calculateFulFillmentRateData.methods.length === 0 &&
        form.getFieldValue('country') && <span className={css.shippingWarning}>{t('checkout:shipping.notAvailable')}</span>}
      {calculateFulFillmentRateData && calculateFulFillmentRateData.methods && calculateFulFillmentRateData.methods.length > 0 && (
        <Form.Item label={t('checkout:form.step2.fields.shippingMethod.label')}>
          {getFieldDecorator('shippingMethod', {
            rules: [
              {
                required: true,
                message: t('checkout:form.step2.fields.shippingMethod.messages.isRequired'),
              },
            ],
          })(
            <Radio.Group
              size="large"
              onChange={(e: RadioChangeEvent) => {
                form.setFieldsValue({ shippingMethod: e.target.value }, () => {
                  validateStep(1);
                });
                setStep(1);
              }}
            >
              {calculateFulFillmentRateData &&
                calculateFulFillmentRateData.methods.map((method: ShippingMethod, index: number) => {
                  return (
                    <Radio.Button
                      key={`${method.name}-${index}`}
                      value={method}
                      onClick={() => dispatch(setSelectedShippingActionCreator(method))}
                    >
                      {getIcon(method.type)}
                      {` ${method.name} - ${t('checkout:before')} ${method.daysMax} ${t('checkout:days')} - ${t(
                        `shell:currencies.${method.currency}`
                      )} ${rates && convert(method.price, constPayformCurrency.Euro, currency)}`}
                    </Radio.Button>
                  );
                })}
            </Radio.Group>
          )}
        </Form.Item>
      )}
    </>
  );
};
