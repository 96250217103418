import React from 'react';
import { Layouts } from '../../../components/components';
import style from './style.module.scss';
import ResultItem from './ResultItem/ResultItem';
import { searchSelector } from '../../../redux/search/search.selector';
import { useSelector } from 'react-redux';

const SearchResult: React.FC = () => {
  const { LayoutBG, Container } = Layouts;
  const {
    search: { filteredProjects },
  } = useSelector(searchSelector);

  return (
    <LayoutBG className={style.background}>
      <Container className={style.searchContainer}>
        {filteredProjects && filteredProjects.length !== 0 ? (
          <h2 className={style.searchTitle}>Вот что мы нашли по твоему запросу:</h2>
        ) : (
          <h2 className={style.searchTitle}>Ничего не найдено</h2>
        )}
        <div className={style.resultList}>
          {filteredProjects && filteredProjects.map((item, index) => <ResultItem data={item} key={item.id || index} />)}
        </div>
      </Container>
    </LayoutBG>
  );
};

export default SearchResult;
