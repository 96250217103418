import { ProductType } from '@altermeliora/payform-types';

export const enumNormalizer = (value: ProductType) => {
  switch (value) {
    case ProductType.material:
      return 'material';
    case ProductType.masterclass:
      return 'masterclass';
    case ProductType.media:
      return 'media';
    case ProductType.project:
      return 'project';
  }
};

export const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 7 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 17 },
  },
};

export const maxStep = 2;
