import React, { useState } from 'react';
import style from './style.module.scss';
import { Layouts } from '../../../components/components';
import ProjectItem from '../ProjectItemsList/ProjectItem/ProjectItem';
import { SitePageContextProjectBlocks } from '../../../graphql-types';
import { BulletItem } from './style';
import { colors } from '../../../styles/variables';

interface ProjectSelectProps {
  data: SitePageContextProjectBlocks | null | undefined;
}

const ProjectSelect: React.FC<ProjectSelectProps> = ({ data }) => {
  const { LayoutBG, Container } = Layouts;
  const [isOpen, setOpen] = useState<boolean>(false);
  return (
    <LayoutBG className={style.wrapper} background={(data?.theme && data.theme?.backgroundColor && data.theme.backgroundColor) || 'none'}>
      <Container className={style.selectContainer}>
        <div className={style.click} onClick={() => setOpen(() => !isOpen)}>
          <ProjectItem
            label={(data?.projectIcon && data.projectIcon.label) || ''}
            iconColor={(data?.projectIcon && data.projectIcon.iconColor) || ''}
            iconType={(data?.projectIcon && data.projectIcon.iconType) || ''}
          >
            {isOpen && (
              <div className={style.childrenList}>
                <ul className={style.navList}>
                  {data?.bulletItem &&
                    data.bulletItem.length !== 0 &&
                    data.bulletItem.map((el, index) => (
                      <BulletItem
                        icon={
                          data?.theme?.bulletImage
                            ? `url(${process.env.STRAPI_API_URL}${data.theme?.bulletImage.url}) center center / cover no-repeat`
                            : 'none'
                        }
                        className={style.bulletItem}
                        key={el?.id || index}
                        style={{ color: (data?.theme && data.theme.mainColor) || colors.black }}
                      >
                        {el?.label}
                      </BulletItem>
                    ))}
                </ul>
                <div className={style.picture}>
                  <img src={`${process.env.STRAPI_API_URL}${data?.picture?.formats?.small?.url}`} alt="" />
                </div>
              </div>
            )}
          </ProjectItem>
        </div>
      </Container>
    </LayoutBG>
  );
};

export default ProjectSelect;
