import { gql } from '@apollo/client';

/**
 * TODO: Add photos to request
 */

export const CART_ITEMS_BY_IDS = gql`
  query($ids: [Float!]!) {
    CartItemsByIds(ids: $ids) {
      id
      quantity
      product {
        id
        name {
          en
          ru
        }
      }
    }
  }
`;
