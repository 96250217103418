import React from 'react';
import { Typography } from 'antd';
import ReactMarkdown from 'react-markdown';
import classNames from 'classnames';
import Hyphenated from 'react-hyphen';
import ru from 'hyphenated-ru';
import { scroller } from 'react-scroll';
import style from './style.module.scss';
import { Layouts, Circle, Button, CardItem } from '../../../components/components';
import { CircleBgType, CardItemType, PromoBlockType, FontWeight } from '../../../lib/constants/enums';
import { StrapiPagesBlocks, SitePageContextProjectTheme, SitePageContextProjectBlocks } from '../../../graphql-types';
import { colors } from '../../../styles/variables';
import ColorService from '../../../lib/services/colorService';
import remarkGfm from 'remark-gfm';

type ImagePostion = 'left' | 'right';

interface PromoProps {
  imagePosition?: ImagePostion;
  type?: PromoBlockType;
  data?: StrapiPagesBlocks | SitePageContextProjectBlocks | null | undefined;
  generalTheme?: SitePageContextProjectTheme | null | undefined;
  promo?: { title?: string; text?: string };
}

const Promo: React.FC<PromoProps> = ({ type, imagePosition = 'left', data, generalTheme, promo }) => {
  const { Container, LayoutBG, AdaptiveLayout } = Layouts;
  const { Title, Text } = Typography;
  const colorService = new ColorService();

  const defaultPromo = (
    <LayoutBG
      background={
        (data?.theme &&
          `url(${process.env.STRAPI_API_URL}${data.theme.backgroundImage?.formats?.large?.url ||
          data.theme.backgroundImage?.url})center top / cover no-repeat`) ||
        colors.white
      }
      className={style.defaultPromoWrap}
    >
      <Container className={style.container}>
        <div className={style.defaultPromoList}>
          <div className={style.itemContent}>
            {data?.additionalTitle && (
              <h5 className={style.defaultAdditionalTitle}>
                {data.additionalTitle}
              </h5>
            )}
            <h1 className={style.defaultPromoTitle}>{data?.title}</h1>
          </div>
          <img className={style.defaultPromoPicture} src={`${process.env.STRAPI_API_URL}${data?.picture?.url}`} alt=""/>
        </div>
      </Container>
    </LayoutBG>
  );

  const scrollToPayment = () => {
    scroller.scrollTo('paymentModule', {
      duration: 1500,
      delay: 100,
      smooth: true,
      offset: 50,
    });
  };

  const withoutCircle = (
    <>
      <AdaptiveLayout onDesktop onTablet>
        <LayoutBG className={style.withoutCircleBg}>
          <Container className={style.withoutCircleContainer}>
            <div className={style.withoutCircleList}>
              <div className={style.withoutCircleItemInfo}>
                <h2 className={style.title}>
                  <ReactMarkdown transformImageUri={img => `${process.env.STRAPI_API_URL}${img}`}
                                 children={data?.title || ''} remarkPlugins={[remarkGfm]}/>
                </h2>
                <div className={style.description}>
                  <ReactMarkdown transformImageUri={img => `${process.env.STRAPI_API_URL}${img}`}
                                 children={data?.description || ''} remarkPlugins={[remarkGfm]}/>
                </div>
                <a href={data?.link || '#'} className={style.link}>
                  <Button title="Хочу эспандеры" border fontSize={23} uppercase fontWeight={600}/>
                </a>
              </div>
              <div className={style.withoutCircleItem}/>
            </div>
          </Container>
        </LayoutBG>
      </AdaptiveLayout>
      <AdaptiveLayout onMobile>
        <Container className={style.withoutCircleContainer}>
          <div className={style.withoutCircleList}>
            <div className={style.withoutCircleItem}>
              <div className={style.image}/>
              <div className={style.info}>
                <ReactMarkdown transformImageUri={img => `${process.env.STRAPI_API_URL}${img}`}
                               children={data?.title || ''} remarkPlugins={[remarkGfm]}/>
              </div>
            </div>
            <div className={style.withoutCircleItemInfo}>
              <div className={style.description}>
                <ReactMarkdown transformImageUri={img => `${process.env.STRAPI_API_URL}${img}`}
                               children={data?.description || ''} remarkPlugins={[remarkGfm]}/>
              </div>
              <a href="#" className={style.link}>
                <Button title="Хочу эспандеры" border fontSize={17} uppercase fontWeight={600}/>
              </a>
            </div>
          </div>
        </Container>
      </AdaptiveLayout>
    </>
  );

  const fitnessEquipment = (
    <>
      {data && (
        <LayoutBG>
          <div className={style.fitnessEquipmentWrapper}>
            <Container className={style.container}>
              <div className={classNames(style.promoList, style.fitnessEquipment)}>
                <div className={style.promoItem} style={{ order: imagePosition && imagePosition === 'left' ? 1 : 2 }}>
                  <div className={style.fitnessEquipmentImage}/>
                  <div className={style.cardDescriptionWrapper}>
                    <AdaptiveLayout onDesktop>
                      <CardItem width="327px" description={data.smallDescription || ''}
                                type={CardItemType.description}/>
                    </AdaptiveLayout>
                  </div>
                </div>
                <div className={style.promoItem} style={{ order: imagePosition && imagePosition === 'right' ? 1 : 2 }}>
                  <div className={style.topBlock}>
                    <Title className={style.promoTitle} level={3}>
                      {data?.firstTitle}
                    </Title>
                    <div className={style.itemTitle}>
                      <Text className={style.titleSecond}>{data?.secondTitle}</Text>
                    </div>
                    <div className={style.fitnessEquipmentImageMobile}/>
                    <div className={style.itemDescription}>
                      <ReactMarkdown transformImageUri={img => `${process.env.STRAPI_API_URL}${img}`}
                                     children={data?.description || ''} remarkPlugins={[remarkGfm]}/>
                    </div>
                  </div>
                  <div className={style.bottomBlock}>
                    <a href={data.link || '#'} className={style.link}>
                      <Button border fontWeight={800} uppercase title="начать худеть"/>
                    </a>
                  </div>
                </div>
              </div>
            </Container>
          </div>
        </LayoutBG>
      )}
    </>
  );

  const about = (
    <LayoutBG>
      <div className={style.absolutlyTitle}>GOSLIM</div>
      <div className={classNames(style.aboutWrapper, { [style.partnersWrapper]: type === PromoBlockType.partners })}>
        <Container className={style.container}>
          <div className={classNames(style.promoList, style.aboutList)}>
            <div className={style.promoItem} style={{ order: imagePosition && imagePosition === 'left' ? 1 : 2 }}>
              <div className={style.promoImage}>
                <AdaptiveLayout onDesktop>
                  <Circle bgType={(type === PromoBlockType.about && CircleBgType.about) || CircleBgType.partners}/>
                </AdaptiveLayout>
                <AdaptiveLayout onTablet onMobile>
                  <Circle
                    bgType={(type === PromoBlockType.about && CircleBgType.about) || CircleBgType.partners}
                    circleWidth="300px"
                    circleHeight="300px"
                  />
                </AdaptiveLayout>
              </div>
            </div>
            <div className={style.promoItem} style={{ order: imagePosition && imagePosition === 'right' ? 1 : 2 }}>
              <div className={style.topBlock}>
                <div className={style.itemTitle}>
                  <h2
                    className={classNames(style.titleSecond, { [style.partnerstitleSecond]: type === PromoBlockType.partners })}>
                    <ReactMarkdown transformImageUri={img => `${process.env.STRAPI_API_URL}${img}`}
                                   children={data?.title || ''} remarkPlugins={[remarkGfm]}/>
                  </h2>
                </div>
                {type === PromoBlockType.partners && (
                  <div className={style.partnersInfo}>
                    <ReactMarkdown transformImageUri={img => `${process.env.STRAPI_API_URL}${img}`}
                                   children={data?.description || ''} remarkPlugins={[remarkGfm]}/>
                  </div>
                )}

                <AdaptiveLayout onTablet onMobile>
                  <div className={style.aboutMobileImage}>
                    <Circle
                      bgType={(type === PromoBlockType.about && CircleBgType.about) || CircleBgType.partners}
                      circleWidth="300px"
                      circleHeight="300px"
                    />
                  </div>
                </AdaptiveLayout>

                {type === PromoBlockType.about && (
                  <>
                    <div className={style.itemDescription}>
                      <ReactMarkdown transformImageUri={img => `${process.env.STRAPI_API_URL}${img}`}
                                     children={data?.description || ''} remarkPlugins={[remarkGfm]}/>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </Container>
      </div>
      {promo?.text && <div className={style.aboutInfo}>
        <div className={style.container}>
          <h2>{promo?.title}</h2>
          <div className={style.reactMarkdown}>
            <ReactMarkdown transformImageUri={img => `${process.env.STRAPI_API_URL}${img}`} children={promo?.text || ''}
                           remarkPlugins={[remarkGfm]}/>
          </div>
        </div>
      </div>}
      {type === PromoBlockType.partners && data?.additionalDescription && (
        <Container className={style.additionalDescriptionContainer}>
          <div className={style.partnersAdditionalInfo}>
            <ReactMarkdown transformImageUri={img => `${process.env.STRAPI_API_URL}${img}`}
                           children={data?.additionalDescription || ''} remarkPlugins={[remarkGfm]}/>
          </div>
        </Container>
      )}
    </LayoutBG>
  );

  const project = (
    <LayoutBG
      background={`url(${process.env.STRAPI_API_URL}${data?.background?.formats?.large?.url ||
      data?.background?.url ||
      data?.theme?.backgroundImage?.url}) center center / cover no-repeat`}
      className={style.projectWrapper}
    >
      <Container className={style.container}>
        <div className={classNames(style.projectList, { [style.inverseList]: data?.inverseContent })}>
          {data?.picture && (
            <div className={classNames(style.projectImage, { [style.projectItemImageInverse]: data.inverseContent })}>
              <img src={`${process.env.STRAPI_API_URL}${data.picture.formats?.medium?.url}`} alt={data?.title || ''}/>
            </div>
          )}
          <div className={classNames(style.projectItem, { [style.inverseItem]: data?.inverseContent })}>
            {data?.additionalTitle && (
              <h3
                style={{
                  color: (data?.theme && data.theme?.textColor) || colors.black,
                }}
                className={style.additionalTitle}
              >
                {data.additionalTitle}
              </h3>
            )}
            <h2 style={{ color: generalTheme?.extraInfoColor || generalTheme?.mainColor || colors.black }}
                className={style.projectTitle}>
              {data?.title?.split(' ').map((word, idx) =>
                word.length > 12 ? (
                  <Hyphenated language={ru} key={idx}>
                    {word}{' '}
                  </Hyphenated>
                ) : (
                  <span key={idx}>{word} </span>
                ),
              )}
            </h2>
            {data?.picture && (
              <div className={style.projectItemImage}>
                <img
                  src={`${process.env.STRAPI_API_URL}${data.picture.formats?.medium?.url || data?.background?.url}`}
                  alt={data?.title || ''}
                />
              </div>
            )}
            <div
              className={classNames(style.projectDescription, { [style.projectDescriptionColor]: data?.theme && data.theme?.textColor })}
            >
              <ReactMarkdown transformImageUri={img => `${process.env.STRAPI_API_URL}${img}`}
                             children={data?.description || ''} remarkPlugins={[remarkGfm]}/>
            </div>
            <div className={style.projectStart}>
              <div style={{ color: generalTheme?.mainColor || colors.black }} className={style.projectData}>
                <ReactMarkdown transformImageUri={img => `${process.env.STRAPI_API_URL}${img}`}
                               children={data?.startProject || ''} remarkPlugins={[remarkGfm]}/>
              </div>
              <span style={{ color: (data?.theme && data.theme?.textColor && data.theme.textColor) || colors.black }}>
                <ReactMarkdown transformImageUri={img => `${process.env.STRAPI_API_URL}${img}`}
                               children={data?.additionalDescription || ''} remarkPlugins={[remarkGfm]}/>
              </span>
            </div>
            {data?.CTA && data?.CTA.buttonTitle && (
              <div className={style.projectLink} onClick={() => scrollToPayment()}>
                <AdaptiveLayout onDesktop onTablet>
                  <a href={data?.CTA?.btnLink} style={{ width: '100%' }}>
                    <Button
                      bgColor={
                        generalTheme?.firstOptionalColor &&
                        generalTheme?.secondOptionalColor &&
                        colorService.generateGradient(generalTheme.firstOptionalColor, generalTheme?.secondOptionalColor, 180)
                      }
                      shadowColor={
                        generalTheme?.firstOptionalColor &&
                        colorService.generateShadow('0px 14px 20px', generalTheme.firstOptionalColor, 0.8)
                      }
                      from={(generalTheme?.firstOptionalColor && generalTheme.firstOptionalColor) || colors.yellow}
                      to={(generalTheme?.secondOptionalColor && generalTheme.secondOptionalColor) || colors.green}
                      title={data?.CTA.buttonTitle}
                      uppercase
                      fontWeight={FontWeight.bold}
                      fontSize={23}
                      border={data.CTA.extraBorder}
                      fontColor={data.CTA.buttonColor || colors.btngray}
                    />
                  </a>
                </AdaptiveLayout>
                <AdaptiveLayout onMobile>
                  <a href={data?.CTA?.btnLink} style={{ width: '100%' }}>
                    <Button
                      bgColor={
                        generalTheme?.firstOptionalColor &&
                        generalTheme?.secondOptionalColor &&
                        colorService.generateGradient(generalTheme.firstOptionalColor, generalTheme?.secondOptionalColor, 180)
                      }
                      shadowColor={
                        generalTheme?.firstOptionalColor &&
                        colorService.generateShadow('0px 14px 20px', generalTheme.firstOptionalColor, 0.8)
                      }
                      from={(generalTheme?.firstOptionalColor && generalTheme.firstOptionalColor) || colors.yellow}
                      to={(generalTheme?.secondOptionalColor && generalTheme.secondOptionalColor) || colors.green}
                      title={data?.CTA.buttonTitle}
                      uppercase
                      fontWeight={FontWeight.bold}
                      fontSize={15}
                      border={data.CTA.extraBorder}
                      fontColor={data.CTA.buttonColor || colors.btngray}
                    />
                  </a>
                </AdaptiveLayout>
              </div>
            )}
          </div>
        </div>
      </Container>
    </LayoutBG>
  );

  const promoBloksArray = [
    {
      type: PromoBlockType.default,
      block: defaultPromo,
    },
    {
      type: PromoBlockType.withoutCircle,
      block: withoutCircle,
    },
    {
      type: PromoBlockType.fitnessEquipment,
      block: fitnessEquipment,
    },
    {
      type: PromoBlockType.about,
      block: about,
    },
    {
      type: PromoBlockType.partners,
      block: about,
    },
    {
      type: PromoBlockType.project,
      block: project,
    },
  ];

  const findBlockByType = promoBloksArray.find(block => block.type === type);
  if (type && findBlockByType) return findBlockByType.block;

  return defaultPromo;
};

export default Promo;
