import React from 'react';
import style from './style.module.scss';
import { StarFilled, PlusCircleFilled, CheckCircleFilled } from '@ant-design/icons';
import ReactMarkdown from 'react-markdown';
import { colors } from '../../../../styles/variables';
import remarkGfm from 'remark-gfm';

enum IconType {
  star = 'star',
  plus = 'plus',
  check = 'check',
}

interface ProjectItemProps {
  label: string;
  iconType?: string;
  iconColor?: string;
  children?: JSX.Element | React.FC | boolean;
  borderColor?: string;
}

const ProjectItem: React.FC<ProjectItemProps> = ({
  label,
  iconType = IconType.star,
  iconColor,
  children,
  borderColor = colors.mainColor,
}) => {
  const iconStyle = { fontSize: '30px', color: iconColor };
  const renderIcon =
    iconType === IconType.plus ? (
      <PlusCircleFilled style={iconStyle} />
    ) : iconType === IconType.check ? (
      <CheckCircleFilled style={iconStyle} />
    ) : (
      <StarFilled style={iconStyle} />
    );
  return (
    <div className={style.item} style={{ borderColor: borderColor }}>
      <div className={style.itemList}>
        <div className={style.icon}>{renderIcon}</div>
        <span className={style.label}>
          <ReactMarkdown transformImageUri={img => `${process.env.STRAPI_API_URL}${img}`} children={label} remarkPlugins={[remarkGfm]} />
        </span>
      </div>
      {children}
    </div>
  );
};

export default ProjectItem;
