import React from 'react';
import style from './style.module.scss';
import { Layouts, Circle, Button } from '../../../components/components';
import cx from 'classnames';
import { PictureWithTextType } from '../../../lib/constants/enums';
import ReactMarkdown from 'react-markdown';
import { StrapiPagesBlocks, SitePageContextProjectBlocks } from '../../../graphql-types';
import remarkGfm from 'remark-gfm';

interface PictureWithTextProps {
  type?: PictureWithTextType;
  description?: string;
  image?: string;
  data?: StrapiPagesBlocks | SitePageContextProjectBlocks | null | undefined;
}

const PictureWithText: React.FC<PictureWithTextProps> = ({ type = PictureWithTextType.default, description, image, data }) => {
  const { LayoutBG, Container, AdaptiveLayout } = Layouts;

  const descriptionType = (
    <div className={style.descriptionBlockWrap}>
      <Container className={style.container}>
        {image ? (
          <div className={style.descroptionBlockContent}>
            <div className={style.descroptionBlockInfo}>
              <ReactMarkdown transformImageUri={img => `${process.env.STRAPI_API_URL}${img}`} children={description || ''} remarkPlugins={[remarkGfm]} />
            </div>
            <div className={style.descroptionBlockImage}>
              <img src={`${process.env.STRAPI_API_URL}${image}`} />
            </div>
          </div>
        ) : (
          <>
            <div className={style.border} />
            <div className={style.descriptionWithoutImage}>
              <ReactMarkdown transformImageUri={img => `${process.env.STRAPI_API_URL}${img}`} children={description || ''} remarkPlugins={[remarkGfm]} />
            </div>
          </>
        )}
      </Container>
    </div>
  );

  const defaultType = (
    <LayoutBG background={`url(${process.env.STRAPI_API_URL}/uploads/study_bg_9717ec1a57.png)center center / cover no-repeat`}>
      <Container className={style.container}>
        <div className={cx(style.list, style[type])}>
          <div className={style.itemLeft}>
            <img className={style.image} src={`${process.env.STRAPI_API_URL}${data?.picture?.formats?.small?.url}`} alt="" />
          </div>
          <div className={style.itemRight}>
            <h3 className={style.title}>{data?.title}</h3>
            <div className={style.description}>
              <ReactMarkdown transformImageUri={img => `${process.env.STRAPI_API_URL}${img}`} children={data?.description || ''} remarkPlugins={[remarkGfm]} />
            </div>
          </div>
        </div>
      </Container>
    </LayoutBG>
  );

  const blockWithCircle = (
    <>
      {data && (
        <div className={style.blockWithCircleWrapper}>
          <Container className={style.container}>
            <div className={style.blockWithCircleList}>
              <h2 className={style.topTitle}>{data.title}</h2>
              <div className={style.blockWithCircleItem}>
                <AdaptiveLayout onDesktop>
                  <Circle backgound={`${process.env.STRAPI_API_URL}${data.picture?.url}`} circleWidth={'320px'} circleHeight={'300px'} />
                </AdaptiveLayout>
                <AdaptiveLayout onTablet onMobile>
                  <Circle backgound={`${process.env.STRAPI_API_URL}${data.picture?.url}`} circleWidth={'210px'} circleHeight={'200px'} />
                </AdaptiveLayout>
              </div>
              <div className={style.blockWithCircleInfo}>
                <h2>{data.title}</h2>
                <div>
                  <ReactMarkdown transformImageUri={img => `${process.env.STRAPI_API_URL}${img}`} children={data.description || ''} remarkPlugins={[remarkGfm]} />
                </div>
              </div>
            </div>
          </Container>
        </div>
      )}
    </>
  );

  const blockWithCircleReview = (
    <>
      {data && (
        <div className={style.blockWithCircleReviewWrapper}>
          <Container className={style.container}>
            <h2 className={style.title}>
              <ReactMarkdown transformImageUri={img => `${process.env.STRAPI_API_URL}${img}`} children={data.firstTitle || ''} remarkPlugins={[remarkGfm]} /> <span>{data.secondTitle}</span>
            </h2>
            <div className={style.blockWithCircleList}>
              <div className={style.blockWithCircleItem}>
                <AdaptiveLayout onDesktop>
                  <Circle
                    backgound={`${process.env.STRAPI_API_URL}/uploads/espander_1_e37de6a36a.png`}
                    circleWidth={'320px'}
                    circleHeight={'300px'}
                  />
                </AdaptiveLayout>
                <AdaptiveLayout onTablet onMobile>
                  <Circle
                    backgound={`${process.env.STRAPI_API_URL}/uploads/espander_1_e37de6a36a.png`}
                    circleWidth={'210px'}
                    circleHeight={'200px'}
                  />
                </AdaptiveLayout>
              </div>
              <div className={style.blockWithCircleInfo}>
                <div>
                  <ReactMarkdown transformImageUri={img => `${process.env.STRAPI_API_URL}${img}`} children={data.description || ''} remarkPlugins={[remarkGfm]} />
                </div>
                <a href={data.link || '#'} className={style.link}>
                  <Button title={'Оставить отзыв'} fontSize={16} uppercase={true} border={true} fontWeight={600} />
                </a>
              </div>
            </div>
          </Container>
        </div>
      )}
    </>
  );

  const partners = (
    <>
      {data && (
        <div className={style.partnersWrapper}>
          <Container className={style.container}>
            <div className={style.blockWithCircleList}>
              <div className={style.blockWithCircleItem}>
                <AdaptiveLayout onDesktop>
                  <Circle
                    backgound={`${process.env.STRAPI_API_URL}${data.picture?.url}`}
                    circleWidth={'270px'}
                    circleHeight={'270px'}
                    backgoundWidth={'250px'}
                    backgroundHeight={'190px'}
                    borderCount={4}
                  />
                </AdaptiveLayout>
                <AdaptiveLayout onTablet onMobile>
                  <Circle
                    backgound={`${process.env.STRAPI_API_URL}${data.picture?.url}`}
                    circleWidth={'186px'}
                    circleHeight={'186px'}
                    backgoundWidth={'170px'}
                    backgroundHeight={'130px'}
                    borderCount={3}
                  />
                </AdaptiveLayout>
              </div>
              <div className={style.blockWithCircleInfo}>
                <div>
                  <ReactMarkdown transformImageUri={img => `${process.env.STRAPI_API_URL}${img}`} children={data.description || ''} remarkPlugins={[remarkGfm]} />
                </div>
              </div>
            </div>
          </Container>
        </div>
      )}
    </>
  );

  const blocks = [
    {
      type: PictureWithTextType.default,
      block: defaultType,
    },
    {
      type: PictureWithTextType.descriptionBlock,
      block: descriptionType,
    },
    {
      type: PictureWithTextType.blockWithCircle,
      block: blockWithCircle,
    },
    {
      type: PictureWithTextType.blockWithCircleReview,
      block: blockWithCircleReview,
    },
    {
      type: PictureWithTextType.partners,
      block: partners,
    },
  ];

  const findBlockByType = blocks.find(block => block.type === type);

  return <React.Fragment>{findBlockByType && findBlockByType.block}</React.Fragment>;
};

export default PictureWithText;
