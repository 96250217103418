import React from 'react';
import { useMediaQuery } from 'react-responsive';
import Img, { FluidObject } from 'gatsby-image';
import { Button, Typography } from 'antd';
import Slider from 'react-slick';
import Container from '../../../components/Layouts/Container/Container';
import GQL, { ContentfulBanner } from '../../../graphql-types';
import css from './banner.module.scss';

export interface BannerProps {
  banners: GQL.ContentfulBannerConnection;
  scroll: () => void;
}

const Banner = ({ banners, scroll }: BannerProps) => {
  const isXS = useMediaQuery({ maxWidth: 480 });
  const isMD = useMediaQuery({ minWidth: 480, maxWidth: 768 });
  const isLG = useMediaQuery({ minWidth: 768, maxWidth: 1200 });
  const isXL = useMediaQuery({ minWidth: 1200 });

  const getImage = (node: ContentfulBanner) => {
    if (node.image) {
      if (isXS) return node.image.xs;
      if (isMD) return node.image.md;
      if (isLG) return node.image.lg;
      return node.image.fluid;
    }

    return null;
  };

  const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  return (
    // eslint-disable-next-line
    <Slider {...sliderSettings}>
      {banners &&
        banners.edges.map(banner => {
          // const fluid = banner.node.image && banner.node.image.fluid;
          const image: FluidObject = getImage(banner.node);
          return (
            <Container key={banner.node.id}>
              <Img fluid={image} />
              <Container className={css.titleContainer}>
                <Typography.Title level={1} className={css.title}>
                  {banner.node.title}
                </Typography.Title>
                <Button type="primary" size="large" className={css.primaryButton} onClick={() => scroll()}>
                  {banner.node.ctaLabel}
                </Button>
              </Container>
            </Container>
          );
        })}
    </Slider>
  );
};

export default Banner;
