import React from 'react';
import style from './style.module.scss';
import { Layouts } from '../../../components/components';
import SmallItem from '../SmallItemsList/SmallItem/SmallItem';
import { SmallItemType } from '../../../lib/constants/enums';
import { StrapiPagesBlocks } from '../../../graphql-types';

interface GetItprops {
  data: StrapiPagesBlocks | null | undefined;
}

const GetIt: React.FC<GetItprops> = ({ data }) => {
  const { Container } = Layouts;
  return (
    <div className={style.getItBg}>
      <Container className={style.getItContainer}>
        <h2 className={style.getItTitle}>{data?.title}</h2>
        <div className={style.getItList}>
          {data?.getItItems &&
            data.getItItems.length !== 0 &&
            data.getItItems.map((item, index) => (
              <div className={style.getItItem} key={item?.id || index}>
                <SmallItem data={item} type={SmallItemType.default} />
              </div>
            ))}
        </div>
      </Container>
    </div>
  );
};

export default GetIt;
