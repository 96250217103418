import React from 'react';
import style from './style.module.scss';
import { SmallItemType } from '../../../../lib/constants/enums';
import { StrapiTaplinksTaplinkBlocks, SitePageContextProjectBlocks, SitePageContextProjectTheme } from '../../../../graphql-types';
import ReactMarkdown from 'react-markdown';
import ColorService from '../../../../lib/services/colorService';
import cx from 'classnames';
import remarkGfm from 'remark-gfm';

interface SmallItemProps {
  type: SmallItemType;
  data: StrapiTaplinksTaplinkBlocks | SitePageContextProjectBlocks | null | undefined;
  generalTheme?: SitePageContextProjectTheme | null | undefined;
  backgroundCircle?: boolean;
  shadowImage?: boolean;
  withoutImageBackground?: boolean;
}

const SmallItem: React.FC<SmallItemProps> = ({
  type,
  data,
  backgroundCircle = false,
  generalTheme,
  shadowImage = false,
  withoutImageBackground = false,
}) => {
  const colorService = new ColorService();
  const defaultType = (
    <div className={style[SmallItemType.default]}>
      <img
        style={{ boxShadow: shadowImage ? `3px 3px 10px ${generalTheme?.mainColor}` : 'none', borderRadius: '50%' }}
        src={`${process.env.STRAPI_API_URL}${data?.picture?.url}`}
      />
      <div className={style.infoList}>
        {data?.title && <h4 className={style.title}>{data?.title}</h4>}
        <div className={style.description}>
          <ReactMarkdown transformImageUri={img => `${process.env.STRAPI_API_URL}${img}`} children={data?.description || ''} remarkPlugins={[remarkGfm]} />
        </div>
      </div>
    </div>
  );

  const columntype = (
    <div style={{ maxWidth: type === SmallItemType.column ? '400px' : '300px' }} className={style[SmallItemType.column]}>
      {backgroundCircle && data?.picture && generalTheme?.mainColor && generalTheme?.additionalColor ? (
        <div
          className={cx(style.pictureWithCircle, { [style.pictureWithCircleStatic]: withoutImageBackground })}
          style={{
            background: !withoutImageBackground
              ? colorService.generateGradient(generalTheme.mainColor, generalTheme.additionalColor, 180)
              : 'none',
          }}
        >
          <img
            style={{ boxShadow: shadowImage ? `3px 3px 10px ${generalTheme?.mainColor}` : 'none', borderRadius: '50%' }}
            className={cx({
              [style.imageDefault]: withoutImageBackground,
              [style.positionImage]: !withoutImageBackground,
            })}
            src={`${process.env.STRAPI_API_URL}${data?.picture.url}`}
            alt={data?.title || ''}
          />
        </div>
      ) : (
        <img src={`${process.env.STRAPI_API_URL}${data?.picture?.url}`} alt={data?.title || ''} />
      )}
      <h4 className={style.titleColumn}>{data?.title ? data?.title : ''}</h4>
      <div className={style.description}>
        <ReactMarkdown transformImageUri={img => `${process.env.STRAPI_API_URL}${img}`} children={data?.description || ''} remarkPlugins={[remarkGfm]}/>
      </div>
    </div>
  );

  return type === SmallItemType.default ? defaultType : columntype;
};

export default SmallItem;
