import React from 'react';
import style from './style.module.scss';
import { Layouts } from '../../../components/components';
import ReactMarkdown from 'react-markdown';
import { SitePageContextProjectBlocks } from '../../../graphql-types';
import remarkGfm from 'remark-gfm';

interface ProjectAuthorProps {
  data: SitePageContextProjectBlocks | null | undefined;
}

const ProjectAuthor: React.FC<ProjectAuthorProps> = ({ data }) => {
  const { LayoutBG, Container } = Layouts;
  return (
    <LayoutBG
      className={style.authorWrapper}
      background={data?.theme && data.theme?.backgroundColor && `${data.theme?.backgroundColor}!important`}
    >
      <Container className={style.authorContainer}>
        <div className={style.authorList}>
          <div className={style.authorInfo}>
            <ReactMarkdown transformImageUri={img => `${process.env.STRAPI_API_URL}${img}`} children={data?.description || ''} remarkPlugins={[remarkGfm]} />
          </div>
          <img className={style.authorImage} src={`${process.env.STRAPI_API_URL}${data?.picture?.url}`} alt="author" />
        </div>
      </Container>
    </LayoutBG>
  );
};

export default ProjectAuthor;
