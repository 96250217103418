import React from 'react';
import style from './style.module.scss';
import { Layouts } from '../../../components/components';
import { SitePageContextProjectBlocks } from '../../../graphql-types';
import { colors } from '../../../styles/variables';
import ProjectItem from './ProjectItem/ProjectItem';
import { List } from './style';

interface ProjectItemsListProps {
  data: SitePageContextProjectBlocks | null | undefined;
}

const ProjectItemsList: React.FC<ProjectItemsListProps> = ({ data }) => {
  const { LayoutBG, Container } = Layouts;
  return (
    <LayoutBG className={style.wrapper} background={(data?.theme && data.theme.mainColor && data.theme.mainColor) || 'none'}>
      <Container className={style.container}>
        {data?.title && (
          <h2
            style={{ color: (data?.theme && data.theme.additionalColor && data.theme.additionalColor) || colors.black }}
            className={style.title}
          >
            {data.title}
          </h2>
        )}
        <List three={data?.threeColumns} className={style.list}>
          {data?.ProjectItem &&
            data.ProjectItem.length !== 0 &&
            data.ProjectItem.map((el, index) => (
              <ProjectItem
                borderColor={(data?.theme && data.theme?.borderColor) || colors.mainColor}
                label={el?.label || ''}
                iconType={data.iconType || ''}
                iconColor={data?.iconColor || colors.green}
                key={el?.id || index}
              />
            ))}
        </List>
      </Container>
    </LayoutBG>
  );
};

export default ProjectItemsList;
