import React from 'react';
import style from './style.module.scss';
import { Layouts, CardItem } from '../../../components/components';
import { ButtonType, CardItemType } from '../../../lib/constants/enums';
import { useTranslation } from 'react-i18next';
import { StrapiPagesBlocks } from '../../../graphql-types';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';

interface FreeproductsProps {
  data: StrapiPagesBlocks | null | undefined;
}

const FreeProducts: React.FC<FreeproductsProps> = ({ data }) => {
  const { Container } = Layouts;
  const { t } = useTranslation();
  return (
    <div className={style.wrapper}>
      <div className={style.background} />
      <Container className={style.container}>
        <div className={style.info}>
          <h2 className={style.title}>{data?.title}</h2>
          <div className={style.description}>
            <ReactMarkdown transformImageUri={img => `${process.env.STRAPI_API_URL}${img}`} children={data?.description || ''} remarkPlugins={[remarkGfm]} />
          </div>
        </div>
        <div className={style.list}>
          {data?.DirectionCard &&
            data.DirectionCard.length !== 0 &&
            data.DirectionCard.map((product, index) => (
              <CardItem
                type={CardItemType.direction}
                key={product?.id || index}
                image={`${process.env.STRAPI_API_URL}${product?.picture?.url}`}
                title={product?.description || ''}
                link={product?.link || ''}
                btnType={ButtonType.present}
                btnTitle={product?.button && product.button.CTAtext ? product.button.CTAtext : t('shell:cardItem.gift')}
              />
            ))}
        </div>
      </Container>
    </div>
  );
};

export default FreeProducts;
