import React from 'react';
import classes from './style.module.scss';
import { useTranslation } from 'react-i18next';
import image from '@assets/images/pageUnderConstruction.svg';

const PageUnderConstruction = () => {
  const { t } = useTranslation();

  return (
    <div className={classes.root}>
      <div className={classes.text}>
        <h1 className={classes.title}>{t('shell:projects.pageUnderConstruction.title')}</h1>
        <h5 className={classes.subtitle}>{t('shell:projects.pageUnderConstruction.subtitle')}</h5>
      </div>
      <img src={image} className={classes.img} alt="" />
    </div>
  );
};

export default PageUnderConstruction;
