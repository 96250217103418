import React from 'react';
import moment from 'moment';
import style from './style.module.scss';
import { Typography, Button, Spin, Icon } from 'antd';
// import { navigate } from '@reach/router';
import { CurrencySymbols, pathTo } from '../../../../lib/utils';
import { useTranslation } from 'react-i18next';
import { PayformCartItemResponseDto, PayformPaymentResponseDto } from '../../../../graphql-types';

const productImageStub = require('../../../../assets/icons/product-image-stub.svg');

interface PurchaseItemProps {
  payment: PayformPaymentResponseDto;
  cart: PayformCartItemResponseDto[];
  loading: boolean;
}

const PurchaseItem: React.FC<PurchaseItemProps> = ({ payment, cart, loading }) => {
  const { Title, Text } = Typography;
  const { t, i18n } = useTranslation();

  return (
    <div className={style.purchaseItemWrapper}>
      <div className={style.purchaseItem}>
        <Title level={4}>{`${t('shell:purchasePage.purchaseInfo.purchaseNumber')}№ GoSlim-${payment.id}-${moment(payment.paidAt).format(
          'YYYY'
        )}`}</Title>
        <Spin spinning={loading} indicator={<Icon type="loading" style={{ fontSize: '24px' }} />}>
          <div className={style.productList}>
            {cart &&
              cart.map(item => {
                return (
                  <div key={item.id} className={style.product}>
                    <img
                      src={item.product.photos && item.product.photos.length > 0 ? item.product.photos[0].path : productImageStub}
                      alt="product"
                    />
                    <Text>{item.product.name[i18n.language as LanguageType]}</Text>
                  </div>
                );
              })}
          </div>
        </Spin>
      </div>
      <div className={style.purchaseItem}>
        <div className={style.purchaseInfo}>
          <Text>{`${t('shell:purchasePage.purchaseInfo.date')}${
            payment.paidAt !== null ? moment(payment.paidAt).format('DD-MM-YYYY') : null
          }`}</Text>
          <Text>{`${t('shell:purchasePage.purchaseInfo.price')}${CurrencySymbols[payment.currency]}${payment.total}`}</Text>
          {/* <Button type="primary" onClick={() => navigate(pathTo(`/purchase/${product.id}`))}>
            {t('shell:userProfile.moreInfo')}
          </Button> */}
        </div>
      </div>
    </div>
  );
};

export default PurchaseItem;
