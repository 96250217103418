import React from 'react';
import i18n from '../../../../i18n';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Select, Card, Input } from 'antd';
import { FormComponentProps } from '@ant-design/compatible/lib/form';
import { TFunction } from 'i18next';
import * as ISOCountries from 'i18n-iso-countries';
import { CountryCode, parsePhoneNumberFromString, getCountryCallingCode } from 'libphonenumber-js';
import { useDispatch } from 'react-redux';
import { setCountryCodeActionCreator } from '../../../../redux/geo/geo.actions';

// eslint-disable-next-line @typescript-eslint/no-var-requires
ISOCountries.registerLocale(require('i18n-iso-countries/langs/en.json'));
// eslint-disable-next-line @typescript-eslint/no-var-requires
ISOCountries.registerLocale(require('i18n-iso-countries/langs/ru.json'));

interface Props extends FormComponentProps {
  t: TFunction;
  setStep: (arg: number) => void;
  countryCode?: string;
}

import css from '../checkoutform.module.scss';

export const CheckoutFormStep1: React.FC<Props> = ({ form, t, setStep, countryCode }) => {
  const dispatch = useDispatch();
  const { Option } = Select;
  const { getFieldDecorator } = form;
  const [phonePrefix, updatePhonePrefix] = React.useState<string>('    ');
  const prefix = '+';

  const validatePhone = (_rule: any, value: any, callback: any) => {
    if (value) {
      const countryName = form.getFieldValue('country');
      const country = ISOCountries.getAlpha2Code(countryName, i18n.language) || navigator.language;
      const phoneNumber = parsePhoneNumberFromString(value, country as CountryCode);
      if (phoneNumber && phoneNumber.isValid()) {
        updatePhonePrefix(prefix.concat(phoneNumber.countryCallingCode as string));
        callback();
      } else {
        callback(`${t('checkout:validatePhone.error')} ${countryName}`);
      }
    } else {
      callback();
    }
  };

  React.useEffect(() => {
    if (countryCode) {
      form.setFieldsValue({ country: ISOCountries.getName(countryCode, i18n.language) });
      getPhonePrefix(countryCode);
    }
  }, [countryCode]);

  const countries = Object.values(ISOCountries.getNames(i18n.language));
  const getPhonePrefix = (country: string) => {
    updatePhonePrefix(prefix.concat(getCountryCallingCode(country as CountryCode).toString()));
  };
  const onCountrySelect = () => {
    setStep(0);
    const country = ISOCountries.getAlpha2Code(form.getFieldValue('country'), i18n.language);
    getPhonePrefix(country);
    dispatch(setCountryCodeActionCreator(country));
  };

  return (
    <Card className={css.card}>
      <Form.Item label={t('checkout:form.step1.fields.firstName.label')}>
        {getFieldDecorator('firstName', {
          rules: [
            {
              required: true,
              message: t('checkout:form.step1.fields.firstName.messages.isRequired'),
            },
            {
              min: 3,
              message: t('checkout:form.step1.fields.firstName.messages.tooShort'),
            },
            {
              max: 30,
              message: t('checkout:form.step1.fields.firstName.messages.tooLong'),
            },
          ],
        })(<Input placeholder={t('checkout:form.step1.fields.firstName.placeholder')} onFocus={() => setStep(0)} />)}
      </Form.Item>
      <Form.Item label={t('checkout:form.step1.fields.lastName.label')}>
        {getFieldDecorator('lastName', {
          rules: [
            {
              required: true,
              message: t('checkout:form.step1.fields.lastName.messages.isRequired'),
            },
            {
              min: 3,
              message: t('checkout:form.step1.fields.lastName.messages.tooShort'),
            },
            {
              max: 30,
              message: t('checkout:form.step1.fields.lastName.messages.tooLong'),
            },
          ],
        })(<Input placeholder={t('checkout:form.step1.fields.lastName.placeholder')} onFocus={() => setStep(0)} />)}
      </Form.Item>
      <Form.Item label={t('checkout:form.step1.fields.email.label')}>
        {getFieldDecorator('email', {
          rules: [
            {
              required: true,
              message: t('checkout:form.step1.fields.email.messages.isRequired'),
            },
            {
              type: 'email',
              message: t('checkout:form.step1.fields.email.messages.notValid'),
            },
          ],
        })(<Input placeholder={t('checkout:form.step1.fields.email.placeholder')} onFocus={() => setStep(0)} />)}
      </Form.Item>
      <Form.Item label={t('checkout:form.step1.fields.country.label')}>
        {getFieldDecorator('country', {
          rules: [
            {
              required: true,
              message: t('checkout:form.step1.fields.country.messages.isRequired'),
            },
          ],
        })(
          <Select
            showSearch
            placeholder={t('checkout:form.step1.fields.country.placeholder')}
            onSelect={onCountrySelect}
            onFocus={() => setStep(0)}
          >
            {countries.map(country => (
              <Option value={country} key={country}>
                {country}
              </Option>
            ))}
          </Select>
        )}
      </Form.Item>
      <Form.Item label={t('checkout:form.step1.fields.city.label')}>
        {getFieldDecorator('city', {
          rules: [
            {
              required: true,
              message: t('checkout:form.step1.fields.city.messages.isRequired'),
            },
          ],
        })(<Input placeholder={t('checkout:form.step1.fields.city.placeholder')} onFocus={() => setStep(0)} />)}
      </Form.Item>
      <Form.Item label={t('checkout:form.step1.fields.address.label')}>
        {getFieldDecorator('address', {
          rules: [
            {
              required: true,
              message: t('checkout:form.step1.fields.address.messages.isRequired'),
            },
          ],
        })(<Input placeholder={t('checkout:form.step1.fields.address.placeholder')} onFocus={() => setStep(0)} />)}
      </Form.Item>
      <Form.Item label={t('checkout:form.step1.fields.postalCode.label')}>
        {getFieldDecorator('postalCode', {
          rules: [
            {
              required: true,
              message: t('checkout:form.step1.fields.postalCode.messages.isRequired'),
            },
            {
              min: 3,
              message: t('checkout:form.step1.fields.postalCode.messages.tooShort'),
            },
            {
              max: 10,
              message: t('checkout:form.step1.fields.postalCode.messages.tooLong'),
            },
          ],
        })(<Input placeholder={t('checkout:form.step1.fields.postalCode.placeholder')} onFocus={() => setStep(0)} />)}
      </Form.Item>
      <Form.Item label={t('checkout:form.step1.fields.phone.label')}>
        {getFieldDecorator('phone', {
          rules: [
            {
              required: true,
              message: t('checkout:form.step1.fields.phone.messages.isRequired'),
            },
            {
              validator: validatePhone,
            },
          ],
        })(<Input placeholder={t('checkout:form.step1.fields.phone.placeholder')} prefix={phonePrefix} onFocus={() => setStep(0)} />)}
      </Form.Item>
    </Card>
  );
};
