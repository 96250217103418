import React from 'react';
import style from './style.module.scss';
import { Layouts, Button, Circle } from '../../../components/components';
import { SitePageContextProjectBlocks, SitePageContextProjectTheme } from '../../../graphql-types';
import { BulletItem } from './style';
import { colors } from '../../../styles/variables';
import { FontWeight } from '../../../lib/constants/enums';
import ColorService from '../../../lib/services/colorService';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';

interface BulletListProps {
  data: SitePageContextProjectBlocks | null | undefined;
  generalTheme?: SitePageContextProjectTheme | null | undefined;
}

const BulletList: React.FC<BulletListProps> = ({ data, generalTheme }) => {
  const { Container, LayoutBG, AdaptiveLayout } = Layouts;
  const colorService = new ColorService();
  return (
    <>
      {data?.theme?.backgroundImage ? (
        <LayoutBG
          background={`url(${process.env.STRAPI_API_URL}${data.theme?.backgroundImage?.url}) center center / cover no-repeat`}
          className={style.withBg}
        >
          <Container className={style.bulletContainer}>
            <div className={style.list}>
              <h3 style={{ color: data.theme.mainColor || colors.black }} className={style.title}>
                {data?.title}
              </h3>
              <div className={style.infoItem}>
                <h3 style={{ color: data.theme.mainColor || colors.black }} className={style.title}>
                  {data?.title}
                </h3>
                <ul className={style.bulletList}>
                  {data?.bulletItems &&
                    data.bulletItems.length !== 0 &&
                    data.bulletItems.map((bullet, index) => (
                      <BulletItem
                        icon={data?.theme?.bulletImage ? `url(${process.env.STRAPI_API_URL}${data.theme?.bulletImage.url})` : 'none'}
                        className={style.bulletItem}
                        key={bullet?.id || index}
                        style={{ color: data.theme.mainColor || colors.black }}
                      >
                        {bullet?.label}
                      </BulletItem>
                    ))}
                </ul>
                {data?.CTA && data.CTA.buttonTitle && data.CTA.btnLink && (
                  <a className={style.link} href={data.CTA.btnLink}>
                    <AdaptiveLayout onDesktop onTablet>
                      <Button title={data.CTA.buttonTitle} border={true} uppercase={true} fontSize={23} fontWeight={FontWeight.bold} />
                    </AdaptiveLayout>
                    <AdaptiveLayout onMobile>
                      <Button title={data.CTA.buttonTitle} border={true} uppercase={true} fontSize={15} fontWeight={FontWeight.bold} />
                    </AdaptiveLayout>
                  </a>
                )}
              </div>
              {data.picture && (
                <div className={style.pictureItem}>
                  <img src={`${process.env.STRAPI_API_URL}${data?.picture?.url}`} alt={data?.title || '#katyamedushkina'} />
                </div>
              )}
            </div>
          </Container>
        </LayoutBG>
      ) : (
        <LayoutBG className={style.withoutBg}>
          {data?.withBg && generalTheme?.mainColor && generalTheme.additionalColor && (
            <div
              style={{ background: colorService.generateGradient(generalTheme?.mainColor, generalTheme.additionalColor, 214) }}
              className={style.linearGradient}
            />
          )}
          <Container className={style.bulletContainer}>
            <div className={style.list}>
              <div style={{ order: data?.inverseContent ? 2 : 0 }} className={style.imageItem}>
                {data.withCircle && (
                  <div className={style.bgCircle}>
                    <AdaptiveLayout onDesktop>
                      <Circle
                        circleColor={
                          (generalTheme?.mainColor &&
                            generalTheme?.additionalColor &&
                            colorService.generateGradient(generalTheme?.mainColor, generalTheme?.additionalColor, 240)) ||
                          colors.defaultGradientButton
                        }
                        from={(generalTheme?.mainColor && generalTheme.mainColor) || colors.yellow}
                        to={(generalTheme?.additionalColor && generalTheme.additionalColor) || colors.green}
                        borderStep={120}
                      />
                    </AdaptiveLayout>
                    <AdaptiveLayout onTablet onMobile>
                      <Circle
                        circleColor={
                          (generalTheme?.mainColor &&
                            generalTheme?.additionalColor &&
                            colorService.generateGradient(generalTheme?.mainColor, generalTheme?.additionalColor, 240)) ||
                          colors.defaultGradientButton
                        }
                        from={(generalTheme?.mainColor && generalTheme.mainColor) || colors.yellow}
                        to={(generalTheme?.additionalColor && generalTheme.additionalColor) || colors.green}
                        borderStep={120}
                        circleWidth={'300px'}
                        circleHeight={'300px'}
                      />
                    </AdaptiveLayout>
                  </div>
                )}
                <img
                  src={`${process.env.STRAPI_API_URL}${data?.picture?.url}`}
                  className={style.image}
                  alt={data?.title || '#katyamedushkina'}
                />
              </div>
              <div className={style.infoItem}>
                <h3 style={{ color: generalTheme?.mainColor ? generalTheme.mainColor : colors.black }} className={style.title}>
                  {data?.title}
                </h3>
                <ul className={style.bulletList}>
                  {data?.bulletItems &&
                    data.bulletItems.length !== 0 &&
                    data.bulletItems.map((bullet, index) => (
                      <BulletItem
                        icon={
                          data?.theme?.bulletImage
                            ? `url(${process.env.STRAPI_API_URL}${data.theme?.bulletImage.url}) center center / contain no-repeat`
                            : 'none'
                        }
                        className={style.bulletItem}
                        key={bullet?.id || index}
                      >
                        <ReactMarkdown transformImageUri={img => `${process.env.STRAPI_API_URL}${img}`} children={bullet?.label || ''} remarkPlugins={[remarkGfm]} />
                      </BulletItem>
                    ))}
                </ul>
                {data?.CTA && data.CTA.buttonTitle && (
                  <a className={style.link} href={data.CTA.btnLink || '#'}>
                    <AdaptiveLayout onDesktop onTablet>
                      <Button title={data.CTA.buttonTitle} border={true} uppercase={true} fontSize={23} fontWeight={FontWeight.bold} />
                    </AdaptiveLayout>
                    <AdaptiveLayout onMobile>
                      <Button title={data.CTA.buttonTitle} border={true} uppercase={true} fontSize={15} fontWeight={FontWeight.bold} />
                    </AdaptiveLayout>
                  </a>
                )}
              </div>
            </div>
          </Container>
        </LayoutBG>
      )}
    </>
  );
};

export default BulletList;
