import {
  Currency,
  InvoiceParticipantFindOrCreateRequestDto,
  InvoicePayerCreateRequestDto,
  CartItemCreateWithoutInvoiceRequestDto,
} from '@altermeliora/payform-types';
import GQL, { PayformCurrency } from '../../graphql-types';
import { IconsType, NavigationName, NavigationPath } from '../constants/enums';

export * from './requests';

export interface MenuItem {
  id: string;
  name: string;
  url: string;
  subItems?: Array<{
    name: string;
    url: string;
  }>;
}

export interface Option {
  contentful_id: string;
  value: string;
}

export interface ColorOption {
  contentful_id: string;
  name: string;
  hexCode: string;
}

export interface ISelectedProject {
  name: GQL.PayformI18nResponseDto;
  id: string;
}

export interface ItemDiscount {
  id: string;
  title: string;
  products: {
    id: number;
    totals: [
      {
        total: number;
        currency: Currency;
      }
    ];
  }[];
}

export interface CartItem {
  product: GQL.PayformProductResponseDto;
  quantity: number;
  option?: GQL.PayformOptionsCategoryResponseDto[];
  selectedOptionIds?: Record<number, number>;
  colorOption?: ColorOption;
  discount?: ItemDiscount;
  project?: ISelectedProject;
}

export interface User {
  _id?: string;
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  country: string;
  countryCode: string;
  city: string;
  address: string;
  postalCode?: string;
}

export enum DeliveryType {
  COMMON = 'COMMON',
  FAST = 'FAST',
  EXPRESS = 'EXPRESS',
  SUPER_EXPRESS = 'SUPER_EXPRESS',
}

export interface ShippingMethod {
  deliveryMethodId: string;
  packagesCount: number;
  type: DeliveryType;
  name: string;
  daysMax: number;
  daysMin: number;
  price: number;
  currency: PayformCurrency;
}

export interface PaymentMethod {
  id?: string;
  name: string;
  icon?: string;
}

export interface OrderStatus {
  id: string;
  status: string;
}

export interface Order {
  id?: string;
  status?: OrderStatus;
  products?: CartItem[];
  user?: User;
  shippingMethod?: ShippingMethod;
  paymentMethod?: PaymentMethod;
  key?: string;
  manager?: string;
}

export interface InvoiceCreateOrder extends Order {
  participant: InvoiceParticipantFindOrCreateRequestDto;
  payer: InvoicePayerCreateRequestDto;
  cart: CartItemCreateWithoutInvoiceRequestDto[];
}

export interface Coordinates {
  lat: number;
  long: number;
}

export interface Geocode {
  plus_code: google.maps.places.PlacePlusCode;
  results: google.maps.GeocoderResult[];
  status: google.maps.GeocoderStatus;
}

export interface UserAuth0 {
  name: string;
  email: string;
  picture: string;
  nickname: string;
  email_verified: boolean;
  family_name: string;
  given_name: string;
  locale: string;
  sub: string;
  updated_at: string;
}

export type LangKey = 'en' | 'ru';

export interface IconKeysInterface extends Array<IconsType> {}

export interface IconInterface {
  Icon: React.FC;
  link: string;
}

export interface ListIconsDataInterface {
  [key: string]: IconInterface;
}

export interface MarkedElementInterface {
  [key: string]: React.FC;
}

export interface NavigationItem {
  name: NavigationName;
  path: NavigationPath;
  id: number;
  link?: string;
}

export interface NavigationData extends Array<NavigationItem> {}
