import React, { useState } from 'react';
import style from './style.module.scss';
import { useTranslation } from 'react-i18next';
import cx from 'classnames';
import ReactMarkdown from 'react-markdown';
import { SitePageContextProjectTheme } from '../../../../graphql-types';
import { Arrow } from './style';
import remarkGfm from 'remark-gfm';

interface QuestionProps {
  title: string;
  description: string;
  generalTheme?: SitePageContextProjectTheme | null | undefined;
}

const Question: React.FC<QuestionProps> = ({ title, description, generalTheme }) => {
  const { t } = useTranslation();
  const [isOpen, setOpen] = useState(false);
  return (
    <div className={style.question}>
      <div className={style.head}>
        <h3 className={style.questionTitle}>{title}</h3>
        <Arrow fill={generalTheme?.mainColor && generalTheme.mainColor} onClick={() => setOpen(() => !isOpen)}>
          <div className={cx(style.arrowIcon, { [style.arrowIconActive]: isOpen })} />
        </Arrow>
      </div>
      {isOpen && (
        <div className={cx(style.description, { [style.descriptionActive]: isOpen })}>
          <ReactMarkdown transformImageUri={img => `${process.env.STRAPI_API_URL}${img}`} children={(description && description) || t('shell:faq.noDescription') || ''} remarkPlugins={[remarkGfm]}/>
        </div>
      )}
    </div>
  );
};

export default Question;
