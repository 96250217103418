import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { StrapiPages } from '../../graphql-types';
import { StrapiMainPagesNames } from '../constants/strapi';
import { strapiSelector } from '../../redux/strapi/strapi.selectors';

export const useStrapiPage = (pageName: StrapiMainPagesNames) => {
  const [data, setData] = useState<StrapiPages | null | undefined>(null);
  const {
    strapi: { pages, projects },
  } = useSelector(strapiSelector);
  
  useEffect(() => {
    if (pages) {
      const page = pages.find(page => page.name === pageName);
      setData(() => page || null);
    }
  }, [pages]);

  return { currentPage: data, pages, projects };
};
