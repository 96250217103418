import React, { useState } from 'react';
import style from './style.module.scss';
import { Layouts, Button } from '../../../components/components';
import cx from 'classnames';
import { QuestionCategory } from '../../../lib/constants/strapi';
import { FontWeight } from '../../../lib/constants/enums';
import Question from './Question/Question';
import { StrapiPagesBlocks, SitePageContextProjectTheme } from '../../../graphql-types';
import { useTranslation } from 'react-i18next';
import ColorService from '../../../lib/services/colorService';
import { colors } from '../../../styles/variables';

interface QuestionListProps {
  data: StrapiPagesBlocks | null | undefined;
  generalTheme?: SitePageContextProjectTheme | null | undefined;
}

const QuestionList: React.FC<QuestionListProps> = ({ data, generalTheme }) => {
  const { LayoutBG, Container, AdaptiveLayout } = Layouts;
  const [currentKey, setCurrentKey] = useState<string>(QuestionCategory.slimmingProjects);
  const { t } = useTranslation();
  const colorService = new ColorService();
  return (
    <LayoutBG className={style.faqbg}>
      <Container className={style.faqContainer}>
        <h2 className={style.faqTitle}>{t('shell:faq.title')}</h2>
        {data && !data.withoutCategory && (
          <div className={style.tabs}>
            <div
              className={cx(style.tabsLefttBtn, { [style.tabActive]: currentKey === QuestionCategory.slimmingProjects })}
              onClick={() => setCurrentKey(QuestionCategory.slimmingProjects)}
            >
              {t('shell:faq.tabs.firstTitle')}
            </div>
            <div
              className={cx(style.tabsRighttBtn, { [style.tabActive]: currentKey === QuestionCategory.generalIssues })}
              onClick={() => setCurrentKey(QuestionCategory.generalIssues)}
            >
              {t('shell:faq.tabs.secondTitle')}
            </div>
          </div>
        )}
        <div className={style.questionList}>
          {data?.Question &&
            data.Question &&
            data.Question.length !== 0 &&
            !data.withoutCategory &&
            data.Question.filter(el => el?.category === currentKey).map((question, index) => (
              <Question generalTheme={generalTheme} title={question?.title || ''} description={question?.description || ''} key={index} />
            ))}
          {data?.Question &&
            data.Question &&
            data.Question.length !== 0 &&
            data.withoutCategory &&
            data.Question.map((question, index) => (
              <Question generalTheme={generalTheme} title={question?.title || ''} description={question?.description || ''} key={index} />
            ))}
          <div className={style.endBlock}>
            <h3 className={style.endBlockTitle}>{t('shell:faq.endBlock.title')}</h3>
            <p className={style.endBlockInfo}>{t('shell:faq.endBlock.info')}</p>
            <a href="https://api.whatsapp.com/send?phone=79654582905&text=" className={style.link} target="_blank">
              <AdaptiveLayout onDesktop onTablet>
                <Button
                  bgColor={
                    generalTheme?.firstOptionalColor &&
                    generalTheme?.secondOptionalColor &&
                    colorService.generateGradient(generalTheme.firstOptionalColor, generalTheme?.secondOptionalColor, 180)
                  }
                  shadowColor={
                    generalTheme?.firstOptionalColor && colorService.generateShadow('0px 14px 20px', generalTheme.firstOptionalColor, 0.8)
                  }
                  from={(generalTheme?.firstOptionalColor && generalTheme.firstOptionalColor) || colors.yellow}
                  to={(generalTheme?.secondOptionalColor && generalTheme.secondOptionalColor) || colors.green}
                  title={t('shell:faq.endBlock.btn')}
                  border={true}
                  uppercase={true}
                  fontWeight={FontWeight.bold}
                  fontSize={23}
                />
              </AdaptiveLayout>
              <AdaptiveLayout onMobile>
                <Button
                  bgColor={
                    generalTheme?.firstOptionalColor &&
                    generalTheme?.secondOptionalColor &&
                    colorService.generateGradient(generalTheme.firstOptionalColor, generalTheme?.secondOptionalColor, 180)
                  }
                  shadowColor={
                    generalTheme?.firstOptionalColor && colorService.generateShadow('0px 14px 20px', generalTheme.firstOptionalColor, 0.8)
                  }
                  from={(generalTheme?.firstOptionalColor && generalTheme.firstOptionalColor) || colors.yellow}
                  to={(generalTheme?.secondOptionalColor && generalTheme.secondOptionalColor) || colors.green}
                  title={t('shell:faq.endBlock.btn')}
                  border={true}
                  uppercase={true}
                  fontWeight={FontWeight.bold}
                  fontSize={16}
                />
              </AdaptiveLayout>
            </a>
          </div>
        </div>
      </Container>
    </LayoutBG>
  );
};

export default QuestionList;
