import React from 'react';
import ReactMarkdown from 'react-markdown';
import { globalHistory } from '@reach/router';
import style from './style.module.scss';
import { Layouts } from '../../../components/components';
import ProjectCart from './ProjectCart/ProjectCart';
import { SitePageContextProject, SitePageContextProjectTheme, StrapiPagesBlocks } from '../../../graphql-types';
import { colors } from '../../../styles/variables';
import { comingSoonUrl } from '../../../lib/constants/data';
import ComingSoon from './ComingSoon/ComingSoon';
import PageUnderConstruction from './PageUnderConstruction/PageUnderConstruction';
import remarkGfm from 'remark-gfm';

interface ProjectListProps {
  data: SitePageContextProject[] | null | undefined;
  theme?: SitePageContextProjectTheme | null | undefined;
  strapiData: StrapiPagesBlocks | null | undefined;
}

const control = (data: any) => {
  if (
    (data.length === 0 && globalHistory.location.pathname === comingSoonUrl.nutrilogy) ||
    (data.length === 0 && globalHistory.location.pathname === comingSoonUrl.problemzone)
  ) {
    return <ComingSoon />;
  }
  if (data.length === 0) {
    return <PageUnderConstruction />;
  }
};

const ProjectList: React.FC<ProjectListProps> = ({ data, theme, strapiData }) => {
  const { Container, LayoutBG } = Layouts;
  return (
    <LayoutBG
      background={
        strapiData?.theme && strapiData.theme?.backgroundImage
          ? `url(${process.env.STRAPI_API_URL}${strapiData?.theme.backgroundImage.formats?.large?.url ||
              strapiData?.theme.backgroundImage.url}) center top / cover no-repeat`
          : 'none'
      }
    >
      <Container className={style.projectsContainer}>
        {strapiData?.title && (
          <h2
            style={{
              color: (strapiData.theme && strapiData.theme.textColor) || colors.black,
              textAlign: strapiData.theme ? 'center' : 'left',
            }}
            className={style.listTitle}
          >
            {strapiData.title}
          </h2>
        )}
        {strapiData?.description && (
          <div style={{ color: (strapiData.theme && strapiData.theme.textColor) || colors.black }} className={style.listDescription}>
            <ReactMarkdown transformImageUri={img => `${process.env.STRAPI_API_URL}${img}`} children={strapiData.description} remarkPlugins={[remarkGfm]} />
          </div>
        )}
        <div className={style.projectsList}>
          {data &&
            data.map((page, index) =>
              page.pageInfo ? <ProjectCart data={page.pageInfo} slug={page.Name || ''} key={page.id || index} theme={theme} /> : null
            )}
        </div>
        {data ? control(data) : false}
      </Container>
    </LayoutBG>
  );
};

export default ProjectList;
