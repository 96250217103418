import React from 'react';
import style from './style.module.scss';
import { Layouts, Button } from '../../../components/components';
import SmallItems from './SmallItem/SmallItem';
import { SmallItemType } from '../../../lib/constants/enums';
import ReactMarkdown from 'react-markdown';
import { StrapiPagesBlocks, SitePageContextProjectTheme } from '../../../graphql-types';
import { colors } from '../../../styles/variables';
import cx from 'classnames';
import remarkGfm from 'remark-gfm';

interface SmallItemsListProps {
  data: StrapiPagesBlocks | null | undefined;
  generalTheme?: SitePageContextProjectTheme | null | undefined;
  type: SmallItemType;
  backgroundCircle?: boolean;
}

const SmallItemsList: React.FC<SmallItemsListProps> = ({ data, type, backgroundCircle = false, generalTheme }) => {
  const { Container } = Layouts;
  return (
    <Container className={style.container}>
      <div className={style.content}>
        <h2 className={style.title}>
          <ReactMarkdown transformImageUri={img => `${process.env.STRAPI_API_URL}${img}`} children={data?.title || ''} remarkPlugins={[remarkGfm]} />
        </h2>
        {data?.secondTitle && <h2 className={style.secondTitle}>{data.secondTitle}</h2>}
        <div className={cx(style.itemsList, { [style.smallItemsFlex]: type === SmallItemType.columnSmallItem })}>
          {data?.smallItems &&
            data.smallItems.length !== 0 &&
            data.smallItems.map((item, index) => (
              <SmallItems
                backgroundCircle={backgroundCircle}
                data={item}
                generalTheme={generalTheme}
                key={index}
                type={type}
                shadowImage={data?.shadowImage || false}
                withoutImageBackground={data?.withoutImageBackground || false}
              />
            ))}
        </div>
        {data?.extraInfo && (
          <div style={{ color: generalTheme?.extraInfoColor || colors.black }} className={style.extraInfo}>
            <ReactMarkdown transformImageUri={img => `${process.env.STRAPI_API_URL}${img}`} children={data.extraInfo} remarkPlugins={[remarkGfm]} />
          </div>
        )}
        {data?.CTA && data.CTA.buttonTitle && (
          <a className={style.link} href={data.CTA.btnLink || '#'}>
            <Button
              title={data.CTA.buttonTitle}
              border={false}
              fontColor={colors.white}
              uppercase={true}
              fontSize={20}
              bgColor={generalTheme?.mainColor || colors.green}
              shadowColor={'none'}
            />
          </a>
        )}
      </div>
    </Container>
  );
};

export default SmallItemsList;
