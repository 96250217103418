import React from 'react';
import style from './style.module.scss';
import { UserAuth0 } from '../../../../lib/common-interfaces';
import { UserOutlined } from '@ant-design/icons';
import { Avatar, Typography, Button } from 'antd';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { setLogoutActionCreator } from '../../../../redux/auth/actions';
import { useLocation } from '@reach/router';
import { LogoutOptions } from '@auth0/auth0-spa-js';

interface UserInfoProps {
  data: UserAuth0;
  logout: (options: LogoutOptions) => void;
}

const UserInfo: React.FC<UserInfoProps> = ({ data, logout }) => {
  const { t } = useTranslation();
  const { Text } = Typography;
  const dispatch = useDispatch();
  const location = useLocation();

  const logoutUser = () => {
    dispatch(setLogoutActionCreator());
    logout({ returnTo: location.origin });
  };

  return (
    <>
      {data && (
        <div className={style.userInfo}>
          <div className={style.userInfoItem}>
            <Avatar size={100} shape="square" src={data.picture || ''} icon={<UserOutlined />} className={style.avatar} />
          </div>
          <div className={style.userInfoItem}>
            <Text strong>{data.name}</Text>
            <br />
            <Text>{data.email}</Text>
          </div>
          <div className={style.userInfoItem}>
            <Button type="primary" onClick={() => logoutUser()}>
              {t('shell:signin.logout')}
            </Button>
          </div>
        </div>
      )}
    </>
  );
};

export default UserInfo;
