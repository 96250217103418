import React from 'react';
import style from './style.module.scss';
import { Circle, Layouts, MarkedElement } from '../../../components/components';
import { CircleBgType } from '../../../lib/constants/enums';
import { chunkArray } from '../../../lib/utils';
import { StrapiPagesBlocks, SitePageContextProjectTheme } from '../../../graphql-types';
import ReactMarkdown from 'react-markdown';
import ColorService from '../../../lib/services/colorService';
import { colors } from '../../../styles/variables';
import remarkGfm from 'remark-gfm';

interface CircularListProps {
  data: StrapiPagesBlocks | null | undefined;
  generalTheme?: SitePageContextProjectTheme | null | undefined;
}

const CircularList: React.FC<CircularListProps> = ({ data, generalTheme }) => {
  const { Container, AdaptiveLayout } = Layouts;
  const colorService = new ColorService();
  const filterListItems =
    data && data.MarkedElem && data.MarkedElem.filter(el => !el?.isTopPositionAllowed && !el?.isBottomPositionAllowed);
  const findTopOrBottomItem =
    data && data.MarkedElem && data.MarkedElem.filter(el => el?.isTopPositionAllowed || el?.isBottomPositionAllowed);
  const chunkListItems = chunkArray(filterListItems || [], Math.round(filterListItems.length / 2));

  const countingCoordinateAxes = (arrayLenght: number, startAngle: number, endAngle: number, index: number) => {
    const angleStep = (endAngle - startAngle) / (arrayLenght - 1);
    const r = 260;
    const deg = startAngle + index * angleStep;
    const degInRad = (deg * Math.PI) / 180;
    const x = r * Math.cos(degInRad);
    const y = r * Math.sin(degInRad);
    return [x, y];
  };

  const renderItems = (array, left: boolean) => {
    return array.map((item, index) => {
      const [x, y] = !left ? countingCoordinateAxes(array.length, 150, 210, index) : countingCoordinateAxes(array.length, -30, 30, index);
      return (
        <div
          style={{
            transform: `translate(${x}px, ${y}px)`,
            textAlign: left ? 'left' : 'right',
            left: left ? '50%' : '-31%',
          }}
          className={style.listItem}
          key={item.id}
        >
          <MarkedElement align={'center'} iconPosition={left ? 'left' : 'right'} defaultIconColor={generalTheme?.mainColor || colors.green}>
            {item?.label}
          </MarkedElement>
        </div>
      );
    });
  };

  const renderCircle = (size: string) => (
    <Circle
      bgType={CircleBgType.list}
      circleWidth={size}
      circleHeight={size}
      circleColor={
        (generalTheme?.mainColor &&
          generalTheme?.additionalColor &&
          colorService.generateGradient(generalTheme.mainColor, generalTheme.additionalColor, 180)) ||
        colors.defaultGradientButton
      }
      from={(generalTheme?.mainColor && generalTheme.mainColor) || colors.yellow}
      to={(generalTheme?.additionalColor && generalTheme.additionalColor) || colors.green}
    />
  );

  return (
    <div className={style.circularWrapper}>
      <Container className={style.circulatInfoContainer}>
        <div className={style.circulatInfo}>
          <h2 style={{ color: generalTheme?.mainColor || colors.black }} className={style.curculatTitle}>
            {data?.title}
          </h2>
          <div className={style.circularDescription}>
            <ReactMarkdown transformImageUri={img => `${process.env.STRAPI_API_URL}${img}`} children={data?.description || ''} remarkPlugins={[remarkGfm]}/>
          </div>
          <span className={style.circularNote}>
            <ReactMarkdown transformImageUri={img => `${process.env.STRAPI_API_URL}${img}`} children={data?.additionalDescription || ''} remarkPlugins={[remarkGfm]}/>
          </span>
        </div>
      </Container>
      <div className={style.circularList}>
        <AdaptiveLayout onDesktop>{renderCircle('380px')}</AdaptiveLayout>
        <AdaptiveLayout onTablet>
          <div className={style.circleWrapper}>{renderCircle('300px')}</div>
        </AdaptiveLayout>
        <AdaptiveLayout onMobile>
          <div className={style.circleWrapper}>{renderCircle('250px')}</div>
        </AdaptiveLayout>
        <AdaptiveLayout onDesktop>
          {chunkListItems.map((item, index) => (index === 0 ? renderItems(item, true) : renderItems(item, false)))}
          {findTopOrBottomItem &&
            findTopOrBottomItem.length !== 0 &&
            findTopOrBottomItem.map((item, index) => {
              return (
                <div
                  style={{
                    textAlign: 'center',
                    top: item?.isTopPositionAllowed ? '-50%' : 'auto',
                    bottom: item?.isTopPositionAllowed ? 'auto' : '-50%',
                    left: '50%',
                    transform: 'translateX(-50%)',
                  }}
                  className={style.listItem}
                  key={item?.id || index}
                >
                  <MarkedElement
                    defaultIconColor={generalTheme?.mainColor || colors.green}
                    iconPosition={item?.isTopPositionAllowed ? 'top' : 'bottom'}
                  >
                    {item?.label}
                  </MarkedElement>
                </div>
              );
            })}
        </AdaptiveLayout>
        <AdaptiveLayout onTablet onMobile>
          <div className={style.list}>
            {data &&
              data.MarkedElem &&
              data.MarkedElem.length !== 0 &&
              data.MarkedElem.map((item, index) => (
                <div className={style.circularListItem} key={item?.id || index}>
                  <MarkedElement align={'center'} defaultIconColor={generalTheme?.mainColor || colors.green}>
                    {item?.label}
                  </MarkedElement>
                </div>
              ))}
          </div>
        </AdaptiveLayout>
      </div>
    </div>
  );
};

export default CircularList;
