import React, { useState } from 'react';
import moment from 'moment';
import style from './style.module.scss';
import { Layouts } from '../../../components/components';
import { StrapiArticles } from '../../../graphql-types';
import Articles from './Articles/Articles';

interface LatestArticlesProps {
  data: StrapiArticles[];
}

const LatestArticles: React.FC<LatestArticlesProps> = ({ data }) => {
  const { Container } = Layouts;

  const control = () => {
    if (!data.length) return <div>Скоро тут будет кое-что интересное...</div>;

    const dataShort = data.length >= 6 ? data.splice(data.length - 6, 6) : data;

    return dataShort
      .reverse()
      .map((art, index) => <Articles art={art} slug={art.blog_categories[0].routeName || ''} key={`${index}ARTICLE`} />);
  };

  return (
    <div className={style.latestWrap}>
      <Container className={style.latestContainer}>
        <h4 className={style.latestTitle}>Новые статьи:</h4>
        <div className={style.latestList}>{control()}</div>
      </Container>
    </div>
  );
};

export default LatestArticles;
