import { useEffect, useState, useMemo } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { useLazyQuery } from '@apollo/react-hooks';
import { PAYMENTS_BY_EMAIL } from '../../api/api-apollo/query/payment';
import { CART_ITEMS_BY_IDS } from '../../api/api-apollo/query/cartItems';
import { PayformPaymentCartItemResponseDto, PayformPaymentResponseDto } from '../../graphql-types';

export const usePurchaseList = () => {
  const { user } = useAuth0();

  const email = useMemo(() => user && 'email' in user && user.email, [user]);

  const [cartItemIds, setCartItemIds] = useState<number[]>([]);

  const [paymentsRequest, { data: paymentsApolloResponse, loading: loadingInvoices, error: errorInvoices }] = useLazyQuery(
    PAYMENTS_BY_EMAIL(email)
  );
  const [cartItemsRequest, { data: cartItemsApolloResponse, loading: loadingCartItems, error: errorCartItems }] = useLazyQuery(
    CART_ITEMS_BY_IDS,
    {
      variables: {
        ids: cartItemIds,
      },
    }
  );

  useEffect(() => {
    if (email) {
      paymentsRequest(email);
    }
  }, [email]);

  useEffect(() => {
    if (cartItemIds && cartItemIds.length > 0) {
      cartItemsRequest();
    }
  }, [cartItemIds]);

  useEffect(() => {
    if (paymentsApolloResponse) {
      const cartIds = paymentsApolloResponse.Payments.items
        .map((item: PayformPaymentResponseDto) => {
          return item.cart.map((i: PayformPaymentCartItemResponseDto) => i.cartItemId);
        })
        .flat();
      setCartItemIds(cartIds);
    }
  }, [paymentsApolloResponse]);

  return { paymentsApolloResponse, cartItemsApolloResponse, loadingInvoices, loadingCartItems };
};
