import React from 'react';
import cx from 'classnames';
import style from './style.module.scss';
import { Layouts, CardItem } from '../../../components/components';
import Intro from './Intro/Intro';
import { CardItemType } from '../../../lib/constants/enums';
import { StrapiPagesBlocks } from '../../../graphql-types';
import { pathTo } from '../../../lib/utils';
import { StrapiMainPagesNames } from '../../../lib/constants/strapi';
import { useStrapiPage } from '../../../lib/hooks/useStrapiPage';

interface StudyProps {
  data: StrapiPagesBlocks | null | undefined;
}

const Study: React.FC<StudyProps> = ({ data }) => {
  const { LayoutBG, Container } = Layouts;
  const { currentPage } = useStrapiPage(StrapiMainPagesNames.about);

  return (
    <LayoutBG background="rgb(243, 240, 235)">
      <div
        className={style.studyWrapper}
        style={{ backgroundImage: `url("${process.env.STRAPI_API_URL}${currentPage?.theme.backgroundImage.url}")` }}
      >
        <Container className={style.studyIntroContainer}>
          <Intro data={data && data.studyIntro} />
        </Container>
        <Container className={cx(style.studyListContainer, { [style.smallContainer]: !data?.studyIntro })}>
          <h2 className={style.studyListTitle}>{data?.title}</h2>
          <div className={style.studyList}>
            {data &&
              data.studyCard &&
              data.studyCard.length !== 0 &&
              data.studyCard.map((card, index) => {
                return (
                  <CardItem
                    key={index}
                    image={`${process.env.STRAPI_API_URL}${card?.picture?.formats?.small?.url}` || ''}
                    title={card?.title || ''}
                    description={card?.description || ''}
                    link={card?.link?.includes('http') ? card?.link : pathTo(card?.link || '')}
                    type={CardItemType.direction}
                  />
                );
              })}
          </div>
        </Container>
      </div>
    </LayoutBG>
  );
};

export default Study;
