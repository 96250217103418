import React, { useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import ReactPlayer from 'react-player';
import ReactMarkdown from 'react-markdown';
import style from './style.module.scss';
import { Layouts, CardItem, MarkedElement, SliderComponent, Button, ImageViewer } from '../../../components/components';
import { CardItemType, FontWeight, SliderBlocksType } from '../../../lib/constants/enums';
import { chunkArray } from '../../../lib/utils';
import { StrapiTaplinksFirstPage, StrapiTaplinksSecondPage, StrapiPagesBlocks } from '../../../graphql-types';
import remarkGfm from 'remark-gfm';

interface SliderBlockProps {
  type?: SliderBlocksType;
  data: StrapiTaplinksFirstPage | StrapiTaplinksSecondPage | StrapiPagesBlocks | null | undefined;
}

const SliderBlock: React.FC<SliderBlockProps> = ({ type = SliderBlocksType.taplinkDefault, data }) => {
  const { Container, LayoutBG, AdaptiveLayout } = Layouts;
  const isMd = useMediaQuery({ maxWidth: 876 });
  const [activeSlide, setActiveSlide] = useState(0);
  const settings = {
    appendDots: (dots: React.ReactNode) => (
      <div
        style={{
          bottom: isMd ? 'calc(100% - 372px)' : '-75px',
        }}
      >
        <ul> {dots} </ul>
      </div>
    ),
    responsive: [
      {
        breakpoint: 764,
        settings: {
          dots: true,
        },
      },
    ],
  };

  const TaplinkArrowPrev = ({ currentSlide, slideCount, ...props }) => (
    <button {...props} className={style.taplinkPrev}>
      <div className={style.taplinkPrevArrow} />
    </button>
  );
  const TaplinkArrowNext = ({ currentSlide, slideCount, ...props }) => (
    <button {...props} className={style.taplinkNext}>
      <div className={style.taplinknextArrow} />
    </button>
  );

  const achievementsType = sliderData => (
    <LayoutBG
      background={`url(${process.env.STRAPI_API_URL}/uploads/achievements_bg_1_912559efd8.png) center center / cover no-repeat`}
      className={style.wrapper}
    >
      <h2 className={style.title}>{sliderData.Title}</h2>
      <Container className={style.container}>
        <SliderComponent className={style.slider} {...settings}>
          {sliderData.AchiviementsSlide &&
            sliderData.AchiviementsSlide.length !== 0 &&
            sliderData.AchiviementsSlide.map(item => (
              <div className={style.sliderItem} key={item.id}>
                <div className={style.slideImages}>
                  <div className={style.imagesList}>
                    {item.beforeImage && (
                      <div className={style.imageWrapper}>
                        <img className={style.imageBefore} src={`${process.env.STRAPI_API_URL}${item.beforeImage.url}`} alt="Before" />
                      </div>
                    )}
                    {item.afterImage && (
                      <div className={style.imageWrapper}>
                        <img className={style.imageAfter} src={`${process.env.STRAPI_API_URL}${item.afterImage.url}`} alt="After" />
                      </div>
                    )}
                  </div>
                  <div className={style.card}>
                    <CardItem isTraingle type={CardItemType.review} title={item.title} description={item.description} />
                  </div>
                </div>
                <div className={style.slideInfo}>
                  <div className={style.slideAchievementsList}>
                    <h3 className={style.slideAchievementsListTitle}>После проекта {item.name}</h3>
                    {item.markedElem &&
                      item.markedElem.length !== 0 &&
                      item.markedElem.map(listItem => (
                        <MarkedElement key={listItem.id}>
                          <span className={style.listItem}>{listItem.label}</span>
                        </MarkedElement>
                      ))}
                  </div>
                </div>
              </div>
            ))}
        </SliderComponent>
      </Container>
    </LayoutBG>
  );

  const reviewsType = sliderData => (
    <LayoutBG
      background={
        sliderData.withBg
          ? `url(${process.env.STRAPI_API_URL}/uploads/study_bg_9717ec1a57.png)center center / cover no-repeat`
          : sliderData.theme && sliderData.theme.backgroundImage
          ? `url(${process.env.STRAPI_API_URL}${sliderData.theme.backgroundImage.url})center center / cover no-repeat`
          : 'none'
      }
      className={style.wrapper}
    >
      <div className={style.reviewsWrapper}>
        <Container className={style.container}>
          {sliderData.title && (
            <div className={style.head}>
              <h2 className={style.firstTitle}>{sliderData.title}</h2>
            </div>
          )}
          {sliderData.description && (
            <div className={style.reviewsDescription}>
              <ReactMarkdown transformImageUri={img => `${process.env.STRAPI_API_URL}${img}`} children={sliderData.description} remarkPlugins={[remarkGfm]} />
            </div>
          )}
          <AdaptiveLayout onDesktop onTablet>
            <SliderComponent>
              {chunkArray(sliderData.ReviewSlide, 2).map((reviwsGroup, index) => {
                return (
                  <React.Fragment key={index}>
                    <div className={style.reviewsList}>
                      {reviwsGroup.map(review => (
                        <div className={style.reviewsCard} key="bakba">
                          {!review.picture ? (
                            <CardItem type={CardItemType.review} title={review.title} description={review.description} />
                          ) : (
                            <ImageViewer image={`${process.env.STRAPI_API_URL}${review.picture.url}`} className={style.reviewPicture} />
                          )}
                        </div>
                      ))}
                    </div>
                  </React.Fragment>
                );
              })}
            </SliderComponent>
          </AdaptiveLayout>
          <AdaptiveLayout onMobile>
            <SliderComponent>
              {sliderData.ReviewSlide.map(review => {
                return (
                  <React.Fragment key={review.id}>
                    <div className={style.reviewsList}>
                      <div className={style.reviewsCard}>
                        {!review.picture ? (
                          <CardItem type={CardItemType.review} title={review.title} description={review.description} />
                        ) : (
                          <ImageViewer image={`${process.env.STRAPI_API_URL}${review.picture.url}`} className={style.reviewPicture} />
                        )}
                      </div>
                    </div>
                  </React.Fragment>
                );
              })}
            </SliderComponent>
          </AdaptiveLayout>
        </Container>
      </div>
    </LayoutBG>
  );

  const reviewsImageType = sliderData => (
    <LayoutBG className={style.wrapper}>
      <div className={style.reviewsImageWrapper}>
        <Container className={style.container}>
          {sliderData.title && (
            <div className={style.head}>
              <h2 className={style.firstTitle}>{sliderData.title}</h2>
            </div>
          )}
          <SliderComponent
            slidesToShow={3}
            responsive={[
              { breakpoint: 1000, settings: { slidesToShow: 2 } },
              { breakpoint: 768, settings: { slidesToShow: 1, dots: false } },
            ]}
          >
            {sliderData.ReviewImageSlide.map((slide, index) => (
              <div className={style.reviewImageSlide} key={index}>
                <ImageViewer image={`${process.env.STRAPI_API_URL}${slide.picture.url}`} />
              </div>
            ))}
          </SliderComponent>
        </Container>
      </div>
    </LayoutBG>
  );

  const taplinkDefaultType = sliderData => (
    <div className={style.taplinkWrapper}>
      <Container className={style.taplinkContainer}>
        <div className={style.taplinkHead}>
          <h2 className={style.taplinkTitle}>{sliderData?.title}</h2>
        </div>
        <div className={style.sliderWrapper}>
          <SliderComponent
            dots={false}
            afterChange={index => setActiveSlide(index)}
            prevArrow={<TaplinkArrowPrev />}
            nextArrow={<TaplinkArrowNext />}
          >
            {sliderData.Slide &&
              sliderData.Slide.map((slide, index) => (
                <div className={style.taplinkSlide} key={slide.id || index}>
                  <img className={style.taplinkImage} src={`${process.env.STRAPI_API_URL}${slide.picture?.url}`} alt="" />
                  {slide?.description && <span className={style.taplinkOverlayInfo}>{slide.description}</span>}
                </div>
              ))}
          </SliderComponent>
        </div>
        <div className={style.slideCounter}>
          <span>
            {activeSlide + 1} - {sliderData.Slide.length}
          </span>
        </div>
      </Container>
    </div>
  );

  const taplinkReviewsType = sliderData => (
    <div className={style.taplinkWrapper}>
      <Container className={style.taplinkContainer}>
        <SliderComponent
          dots={false}
          afterChange={index => setActiveSlide(index)}
          prevArrow={<TaplinkArrowPrev />}
          nextArrow={<TaplinkArrowNext />}
        >
          {sliderData.Slide.map((slide, index) => (
            <div className={style.taplinkReviewSlide} key={index}>
              <img className={style.taplinkImage} src={`${process.env.STRAPI_API_URL}${slide.picture.url}`} />
            </div>
          ))}
        </SliderComponent>
        <div className={style.slideCounter}>
          <span>
            {activeSlide + 1} - {sliderData.Slide.length}
          </span>
        </div>
      </Container>
    </div>
  );

  const taplinkVideoType = sliderData => (
    <div className={style.taplinkWrapper}>
      <Container className={style.taplinkContainer}>
        <div className={style.taplinkHead}>
          <h2 className={style.taplinkTitle}>{sliderData.title}</h2>
        </div>
        <div className={style.sliderWrapper}>
          <SliderComponent
            dots={false}
            afterChange={index => setActiveSlide(index)}
            prevArrow={<TaplinkArrowPrev />}
            nextArrow={<TaplinkArrowNext />}
          >
            {sliderData.Slide.map((slide, index) => (
              <div className={style.taplinkVideoSlide} key={index}>
                {slide.videoLink ? (
                  <iframe
                    className={style.taplinkVideoFrame}
                    src={slide.videoLink}
                    width="100%"
                    height="220px"
                    frameBorder="0"
                    allow="autoplay; fullscreen"
                  />
                ) : (
                  <ReactPlayer
                    width="100%"
                    height="180px"
                    url={`${process.env.STRAPI_API_URL}${slide.video.url}`}
                    light={slide.poster && slide.poster.url && `${process.env.STRAPI_API_URL}${slide.poster.url}`}
                    controls
                    playIcon={<div className={style.playBtn} />}
                  />
                )}
                <div className={style.videoDescription}>
                  <ReactMarkdown transformImageUri={img => `${process.env.STRAPI_API_URL}${img}`} children={slide.description || ''} remarkPlugins={[remarkGfm]} />
                </div>
              </div>
            ))}
          </SliderComponent>
        </div>
        <div className={style.slideCounter}>
          <span>
            {activeSlide + 1} - {sliderData.Slide.length}
          </span>
        </div>
      </Container>
    </div>
  );

  const beforeAfterSlider = sliderData => (
    <div className={style.bfWrapper}>
      <div className={style.bfHead}>
        <h3 className={style.title}>{sliderData?.title}</h3>
        <div className={style.info}>
          <ReactMarkdown transformImageUri={img => `${process.env.STRAPI_API_URL}${img}`} children={sliderData?.description} remarkPlugins={[remarkGfm]} />
        </div>
      </div>
      <Container className={style.container}>
        <div className={style.bfSlider}>
          <SliderComponent>
            {sliderData.BeforeAfterSlide &&
              sliderData.BeforeAfterSlide.length !== 0 &&
              sliderData.BeforeAfterSlide.map((slide, index) => (
                <div className={style.bfSlide} key={slide.id || index}>
                  <div className={style.bfSlideHead}>
                    <span>До</span>
                    <span>После</span>
                  </div>
                  <div className={style.bfImages}>
                    <img src={`${process.env.STRAPI_API_URL}${slide.before?.url}`} alt="before" />
                    <img src={`${process.env.STRAPI_API_URL}${slide.after?.url}`} alt="after" />
                  </div>
                </div>
              ))}
          </SliderComponent>
        </div>
        {sliderData?.CTA && sliderData.CTA?.buttonTitle && (
          <a href={sliderData.CTA?.btnLink || '#'} className={style.bfLink}>
            <Button title={sliderData.CTA?.buttonTitle} border uppercase fontSize={16} fontWeight={FontWeight.bold} />
          </a>
        )}
      </Container>
    </div>
  );

  const sliderArr = [
    {
      slider: achievementsType,
      type: SliderBlocksType.achievements,
    },
    {
      slider: reviewsType,
      type: SliderBlocksType.reviews,
    },
    {
      slider: taplinkDefaultType,
      type: SliderBlocksType.taplinkDefault,
    },
    {
      slider: taplinkReviewsType,
      type: SliderBlocksType.taplinkReviews,
    },
    {
      slider: taplinkVideoType,
      type: SliderBlocksType.taplinkVideo,
    },
    {
      slider: reviewsImageType,
      type: SliderBlocksType.reviewImage,
    },
    {
      slider: beforeAfterSlider,
      type: SliderBlocksType.beforeAfter,
    },
  ];

  const findSliderByType = sliderArr.find(slider => slider.type === type);

  return <>{findSliderByType && findSliderByType.slider(data)}</>;
};

export default SliderBlock;
