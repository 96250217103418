import React from 'react';
import style from './style.module.scss';
import { Circle } from '../../../../components/components';
import { StrapiPagesBlocksStudyIntro } from '../../../../graphql-types';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';

interface IntroProps {
  data: StrapiPagesBlocksStudyIntro | null | undefined;
}

const Intro: React.FC<IntroProps> = ({ data }) => {
  return (
    <>
      {data && (
        <div className={style.introWrapper}>
          <div className={style.introContent}>
            <div className={style.introInfo}>
              <ReactMarkdown transformImageUri={img => `${process.env.STRAPI_API_URL}${img}`} children={data.description || ''} remarkPlugins={[remarkGfm]} />
            </div>
            <div className={style.introCircle}>
              <Circle circleWidth={'146px'} circleHeight={'146px'} />
            </div>
            <span className={style.introNote}>
              <ReactMarkdown transformImageUri={img => `${process.env.STRAPI_API_URL}${img}`} children={data.additionalDescription || ''} remarkPlugins={[remarkGfm]} />
            </span>
            <div className={style.introBgSheet} />
          </div>
        </div>
      )}
    </>
  );
};

export default Intro;
