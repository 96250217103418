import styled from 'styled-components';

export const BulletItem = styled.li`
  position: relative;
  &::before {
    content: '';
    width: 25px;
    height: 25px;
    position: absolute;
    top: 3px;
    left: -50px;
    background: ${props => props.icon && props.icon};
    background-size: contain;
  }
`;
