import React from 'react';
import style from './style.module.scss';
import { Layouts, Button, Circle } from '../../../components/components';
import { FontWeight } from '../../../lib/constants/enums';
import { SitePageContextProjectBlocks, SitePageContextProjectTheme } from '../../../graphql-types';
import ReactMarkdown from 'react-markdown';
import ColorService from '../../../lib/services/colorService';
import { colors } from '../../../styles/variables';
import { Element } from 'react-scroll';
import remarkGfm from 'remark-gfm';

interface PaymentModuleProps {
  data: SitePageContextProjectBlocks | null | undefined;
  generalTheme?: SitePageContextProjectTheme | null | undefined;
}

const PaymentModule: React.FC<PaymentModuleProps> = ({ data, generalTheme }) => {
  const { LayoutBG, Container, AdaptiveLayout } = Layouts;
  const colorService = new ColorService();

  const renderBtn = (title: string | null | undefined, fontSize: number) => (
    <Button
      bgColor={
        (generalTheme?.firstOptionalColor &&
          generalTheme?.secondOptionalColor &&
          colorService.generateGradient(generalTheme.firstOptionalColor, generalTheme?.secondOptionalColor, 180)) ||
        colors.defaultGradientButton
      }
      shadowColor={
        (generalTheme?.firstOptionalColor && colorService.generateShadow('0px 14px 20px', generalTheme.firstOptionalColor, 0.8)) ||
        colors.greenShadow
      }
      from={(generalTheme?.firstOptionalColor && generalTheme.firstOptionalColor) || colors.yellow}
      to={(generalTheme?.secondOptionalColor && generalTheme.secondOptionalColor) || colors.green}
      title={title || 'Оплатить'}
      border={true}
      uppercase={true}
      fontWeight={FontWeight.bold}
      fontSize={fontSize}
      fontColor={(data?.theme && data.theme.extraInfoColor) || colors.btngray}
    />
  );

  const textStyle = {
    color: (data?.theme && data.theme?.backgroundColor && colorService.getContrastYIQ(data.theme.backgroundColor)) || colors.white,
  };

  return (
    <Element name="paymentModule">
      <LayoutBG
        background={
          (data?.theme &&
            data.theme?.backgroundImage &&
            `url(${process.env.STRAPI_API_URL}${data.theme.backgroundImage.url}) center top / cover no-repeat`) ||
          (data?.theme?.backgroundColor && data.theme.backgroundColor) ||
          colorService.generateGradient(generalTheme?.mainColor, generalTheme?.additionalColor, 214)
        }
        className={style.paymentWrapper}
      >
        <Container className={style.paymentContainer}>
          <div className={style.paymentHead}>
            <h3 style={textStyle} className={style.paymentTitle}>
              {data?.title}
            </h3>
            <div style={textStyle} className={style.projectDate}>
              <ReactMarkdown transformImageUri={img => `${process.env.STRAPI_API_URL}${img}`} children={data?.date || ''} remarkPlugins={[remarkGfm]} />
            </div>
          </div>
          <div className={style.paymentList}>
            {data?.paymentItems &&
              data.paymentItems.length !== 0 &&
              data.paymentItems.map((block, index) => (
                <div className={style.paymentItem} key={block?.id || index}>
                  <div
                    className={style.paymentItemHead}
                    style={{
                      background:
                        generalTheme?.extraInfoColor ||
                        (generalTheme?.firstOptionalColor &&
                          generalTheme?.secondOptionalColor &&
                          colorService.generateGradient(generalTheme?.firstOptionalColor, generalTheme?.secondOptionalColor, 240)) ||
                        colors.defaultGradientButton,
                      boxShadow: generalTheme?.extraInfoColor
                        ? colorService.generateShadow('0px 14px 34px', generalTheme.extraInfoColor, 0.8)
                        : generalTheme?.firstOptionalColor
                        ? colorService.generateShadow('0px 14px 34px', generalTheme.firstOptionalColor, 0.8)
                        : colors.greenShadow,
                      color: (data?.theme && data.theme.extraInfoColor) || colors.black,
                    }}
                  >
                    {block?.title}
                  </div>
                  {block?.description && (
                    <div className={style.description}>
                      <ReactMarkdown transformImageUri={img => `${process.env.STRAPI_API_URL}${img}`} children={block.description || ''} remarkPlugins={[remarkGfm]} />
                    </div>
                  )}
                  <div className={style.price}>{block?.price}</div>
                  <a className={style.paymentLink} href={block?.link || '#'}>
                    <AdaptiveLayout onDesktop>{renderBtn(block?.buttonTitle, 23)}</AdaptiveLayout>
                    <AdaptiveLayout onTablet>{renderBtn(block?.buttonTitle, 21)}</AdaptiveLayout>
                    <AdaptiveLayout onMobile>{renderBtn(block?.buttonTitle, 16)}</AdaptiveLayout>
                  </a>
                  {block?.additionalPrice && block.additionalBtnTitle && (
                    <div className={style.additionalPriceBlock}>
                      <div className={style.price}>{block?.additionalPrice}</div>
                      <a className={style.paymentLink} href={block?.additionalLink || '#'}>
                        <AdaptiveLayout onDesktop>{renderBtn(block?.additionalBtnTitle, 23)}</AdaptiveLayout>
                        <AdaptiveLayout onTablet>{renderBtn(block?.additionalBtnTitle, 21)}</AdaptiveLayout>
                        <AdaptiveLayout onMobile>{renderBtn(block?.additionalBtnTitle, 16)}</AdaptiveLayout>
                      </a>
                    </div>
                  )}
                </div>
              ))}
          </div>
          {data?.extraInfo && (
            <div className={style.extraInfoParent}>
              <div className={style.extraInfo}>
                <ReactMarkdown transformImageUri={img => `${process.env.STRAPI_API_URL}${img}`} children={data?.extraInfo || ''} remarkPlugins={[remarkGfm]} />
                <div className={style.paymentCircle}>
                  <Circle
                    circleColor={
                      (generalTheme?.mainColor &&
                        generalTheme?.additionalColor &&
                        colorService.generateGradient(generalTheme?.mainColor, generalTheme?.additionalColor, 240)) ||
                      colors.defaultGradientButton
                    }
                    from={(generalTheme?.mainColor && generalTheme.mainColor) || colors.yellow}
                    to={(generalTheme?.additionalColor && generalTheme.additionalColor) || colors.green}
                  />
                </div>
              </div>
            </div>
          )}
        </Container>
      </LayoutBG>
    </Element>
  );
};

export default PaymentModule;
