import React from 'react';
import style from './style.module.scss';
import { Layouts, CardItem } from '../../../components/components';
import { CardItemType } from '../../../lib/constants/enums';
import { StrapiPagesBlocks } from '../../../graphql-types';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';

interface NewsProps {
  data: StrapiPagesBlocks | null | undefined;
}

const News: React.FC<NewsProps> = ({ data }) => {
  const { Container } = Layouts;
  return (
    <div className={style.wrapper}>
      <Container className={style.container}>
        <div className={style.infoBlock}>
          <h2 className={style.title}>{data?.title}</h2>
          <div className={style.description}>
            <ReactMarkdown transformImageUri={img => `${process.env.STRAPI_API_URL}${img}`} children={data?.description || ''} remarkPlugins={[remarkGfm]} />
          </div>
        </div>
        <div className={style.newsList}>
          {data?.NewsCard &&
            data.NewsCard.length !== 0 &&
            data.NewsCard.map((news, index) => (
              <CardItem
                type={CardItemType.post}
                key={news?.id || index}
                title={news?.title || ''}
                link={news?.link || ''}
                image={`${process.env.STRAPI_API_URL}${news?.picture?.url}`}
                date={news?.date}
              />
            ))}
        </div>
      </Container>
    </div>
  );
};

export default News;
