import React from 'react';
import style from './style.module.scss';
import ReactMarkdown from 'react-markdown';
import { Button } from '../../../../components/components';
import { Link } from 'gatsby';
import { SitePageContextProject } from '../../../../graphql-types';
import { pathTo } from '../../../../lib/utils';
import remarkGfm from 'remark-gfm';

interface ResultItemProps {
  data: SitePageContextProject;
}

const ResultItem: React.FC<ResultItemProps> = ({ data }) => {
  return (
    <div className={style.resultItem}>
      <div className={style.resultInfo}>
        <div className={style.resultInfoFlex}>
          <div className={style.resultImage}>
            <img src={`${process.env.STRAPI_API_URL}${data.pageInfo?.picture?.url}`} alt="" />
          </div>
          <div className={style.info}>
            <h2 className={style.resultTitle}>{data.pageInfo?.title}</h2>
            <div>
              Старт проекта: <strong>{data.pageInfo?.date}</strong>
            </div>
            <div>
              Длительность: <strong>{data.pageInfo?.duration}</strong>
            </div>
            {data.pageInfo && data.pageInfo?.description && (
              <div className={style.resultDescription}>
                <ReactMarkdown transformImageUri={img => `${process.env.STRAPI_API_URL}${img}`} children={data.pageInfo.description || ''} remarkPlugins={[remarkGfm]}/>
              </div>
            )}
          </div>
        </div>
      </div>
      {data.Name?.startsWith('http') ? (
        <a className={style.resultLink} href={data.Name}>
          <Button title="Перейти" border fontSize={20} uppercase fontWeight={600} />
        </a>
      ) : (
        <Link className={style.resultLink} to={pathTo(`/project/${data.Name}`)}>
          <Button title="Перейти" border fontSize={20} uppercase fontWeight={600} />
        </Link>
      )}
    </div>
  );
};

export default ResultItem;
