import React from 'react';
import { FormComponentProps } from '@ant-design/compatible/lib/form';
import Form from 'antd/lib/form';
import { TFunction } from 'i18next';
import { payOnline } from '../../../../lib/constants';
import { Icon as LegacyIcon } from '@ant-design/compatible';
import { Radio } from 'antd';
import { RadioChangeEvent } from 'antd/lib/radio';
import { PaymentProviderResponseDto } from '@altermeliora/payform-types';

import css from '../checkoutform.module.scss';

interface Props extends FormComponentProps {
  PaymentProviderByCountryAbv: PaymentProviderResponseDto[];
  t: TFunction;
  validateStep: (step: number) => void;
  setStep: (value: number) => void;
}

export const CheckoutFormStep3: React.FC<Props> = ({ form, PaymentProviderByCountryAbv, t, validateStep, setStep }) => {
  const { getFieldDecorator } = form;

  return (
    <>
      {PaymentProviderByCountryAbv.length > 0 ? (
        <Form.Item label={t('checkout:form.step3.fields.paymentMethod.label')}>
          {getFieldDecorator('paymentMethod', {
            initialValue: payOnline.name,
            rules: [
              {
                required: true,
                message: t('checkout:form.step3.fields.paymentMethod.messages.isRequired'),
              },
            ],
          })(
            <Radio.Group
              size="large"
              onChange={(e: RadioChangeEvent) => {
                form.setFieldsValue({ paymentMethod: e.target.value }, () => {
                  validateStep(2);
                });
                setStep(2);
              }}
            >
              {PaymentProviderByCountryAbv.map((provider: PaymentProviderResponseDto) => (
                <Radio.Button key={provider.id} value={provider}>
                  {provider.type && <LegacyIcon type={provider.type} className={css.icon} />}
                  {provider.name}
                </Radio.Button>
              ))}
            </Radio.Group>
          )}
        </Form.Item>
      ) : (
        <span className={css.shippingWarning}>Not notAvailable payment providers for this region</span>
      )}
    </>
  );
};
