import React from 'react';
import style from './style.module.scss';
import { Layouts } from '../../../components/components';
import { SitePageContextProjectBlocks, SitePageContextProjectTheme } from '../../../graphql-types';
import ColorService from '../../../lib/services/colorService';
import ReactMarkdown from 'react-markdown';
import { PositionIndex } from './style';
import { colors } from '../../../styles/variables';
import cx from 'classnames';
import remarkGfm from 'remark-gfm';

interface NumberedListProps {
  data: SitePageContextProjectBlocks | null | undefined;
  generalTheme?: SitePageContextProjectTheme | null | undefined;
}

const NumberedList: React.FC<NumberedListProps> = ({ data, generalTheme }) => {
  const { LayoutBG, Container } = Layouts;
  const colorService = new ColorService();

  return (
    <LayoutBG
      className={style.numberedWrapper}
      background={
        data?.theme && data.theme.backgroundImage
          ? `url(${process.env.STRAPI_API_URL}${data.theme.backgroundImage?.url}) center center / cover no-repeat`
          : colors.lightGreyBg
      }
    >
      <Container className={style.numberedContainer}>
        <h2 style={{ color: (data?.theme && data.theme.backgroundImage && colors.white) || colors.black }} className={style.numberedTitle}>
          {data?.title}
        </h2>
        <div className={style.numberedList}>
          {data?.NumberedItem &&
            data?.NumberedItem.length !== 0 &&
            data?.NumberedItem.map((block, index) => (
              <div className={style.numberedItem} key={block?.id || index}>
                {block?.title && <div className={style.numberedItemTitle}>{block?.title}</div>}
                <div className={style.numberedInfo}>
                  <ReactMarkdown transformImageUri={img => `${process.env.STRAPI_API_URL}${img}`} children={block?.description || ''} remarkPlugins={[remarkGfm]} />
                </div>
                <PositionIndex
                  background={
                    (generalTheme?.mainColor &&
                      generalTheme?.additionalColor &&
                      colorService.generateGradient(generalTheme.mainColor, generalTheme.additionalColor, 180)) ||
                    colors.black
                  }
                  textShadow={generalTheme?.mainColor && colorService.generateShadow('4px 4px 48px', generalTheme.mainColor, 0.3)}
                  className={cx(style.positionIndex, { [style.positionIndexSmall]: index > 8 })}
                >
                  {index + 1}
                </PositionIndex>
              </div>
            ))}
        </div>
        {data?.extraInfo && (
          <div className={style.numberedExtraInfo}>
            <ReactMarkdown transformImageUri={img => `${process.env.STRAPI_API_URL}${img}`} children={data.extraInfo} remarkPlugins={[remarkGfm]} />
          </div>
        )}
      </Container>
    </LayoutBG>
  );
};

export default NumberedList;
