import React from 'react';
import style from './style.module.scss';
import { SitePageContextProjectBlocks, SitePageContextProjectTheme } from '../../../graphql-types';
import { Layouts, Button } from '../../../components/components';
import ColorService from '../../../lib/services/colorService';
import ReactMarkdown from 'react-markdown';
import { colors } from '../../../styles/variables';
import { FontWeight } from '../../../lib/constants/enums';
import remarkGfm from 'remark-gfm';

interface BlockWithCircleImageProps {
  data: SitePageContextProjectBlocks | null | undefined;
  generalTheme?: SitePageContextProjectTheme | null | undefined;
}

const BlockWithCircleImage: React.FC<BlockWithCircleImageProps> = ({ data, generalTheme }) => {
  const { Container, LayoutBG, AdaptiveLayout } = Layouts;
  const colorService = new ColorService();

  return (
    <LayoutBG
      className={style.wrapper}
      background={
        generalTheme?.mainColor && generalTheme?.additionalColor
          ? colorService.generateGradient(generalTheme.mainColor, generalTheme?.additionalColor, 240)
          : 'none'
      }
    >
      <Container className={style.blockWithCircleContainer}>
        <div className={style.blockflex}>
          <div style={{ order: data?.inverseContent ? 2 : 1 }} className={style.blockPicture}>
            {data?.picture && <img src={`${process.env.STRAPI_API_URL}${data.picture?.url}`} alt="" />}
          </div>
          <div style={{ order: data?.inverseContent ? 1 : 2 }} className={style.blockContent}>
            <h3 className={style.blockTitle}>{data?.title}</h3>
            <div className={style.blockInfo}>
              <ReactMarkdown transformImageUri={img => `${process.env.STRAPI_API_URL}${img}`} children={data?.description || ''} remarkPlugins={[remarkGfm]} />
            </div>
            {data?.CTA && data.CTA.buttonTitle && (
              <a className={style.blockLink} href={data.CTA.btnLink || '#'}>
                <AdaptiveLayout onDesktop onTablet>
                  <Button
                    bgColor={
                      (generalTheme?.firstOptionalColor &&
                        generalTheme?.secondOptionalColor &&
                        colorService.generateGradient(generalTheme.firstOptionalColor, generalTheme?.secondOptionalColor, 180)) ||
                      colors.defaultGradientButton
                    }
                    shadowColor={
                      (generalTheme?.firstOptionalColor &&
                        colorService.generateShadow('0px 14px 20px', generalTheme.firstOptionalColor, 0.8)) ||
                      colors.greenShadow
                    }
                    from={(generalTheme?.firstOptionalColor && generalTheme.firstOptionalColor) || colors.yellow}
                    to={(generalTheme?.secondOptionalColor && generalTheme.secondOptionalColor) || colors.green}
                    title={data.CTA.buttonTitle}
                    uppercase={true}
                    fontWeight={FontWeight.bold}
                    fontColor={data.CTA.buttonColor || colors.btngray}
                    fontSize={18}
                  />
                </AdaptiveLayout>
                <AdaptiveLayout onMobile>
                  <Button
                    bgColor={
                      (generalTheme?.firstOptionalColor &&
                        generalTheme?.secondOptionalColor &&
                        colorService.generateGradient(generalTheme.firstOptionalColor, generalTheme?.secondOptionalColor, 180)) ||
                      colors.defaultGradientButton
                    }
                    shadowColor={
                      (generalTheme?.firstOptionalColor &&
                        colorService.generateShadow('0px 14px 20px', generalTheme.firstOptionalColor, 0.8)) ||
                      colors.greenShadow
                    }
                    from={(generalTheme?.firstOptionalColor && generalTheme.firstOptionalColor) || colors.yellow}
                    to={(generalTheme?.secondOptionalColor && generalTheme.secondOptionalColor) || colors.green}
                    title={data.CTA.buttonTitle}
                    uppercase={true}
                    fontWeight={FontWeight.bold}
                    fontColor={data.CTA.buttonColor || colors.btngray}
                    fontSize={16}
                  />
                </AdaptiveLayout>
              </a>
            )}
          </div>
        </div>
      </Container>
    </LayoutBG>
  );
};

export default BlockWithCircleImage;
