import React from 'react';
import style from './style.module.scss';
import { Spin, Typography } from 'antd';
import PurchaseItem from '../PurchaseItem/PurchaseItem';
import { useTranslation } from 'react-i18next';
import { usePurchaseList } from '../../../../lib/hooks/usePurchaseList';
import { PayformPaymentResponseDto } from '../../../../graphql-types';

const PurchaseList: React.FC = () => {
  const { Title } = Typography;
  const { t } = useTranslation();

  const { paymentsApolloResponse: payments, cartItemsApolloResponse: cartItems, loadingInvoices, loadingCartItems } = usePurchaseList();

  return (
    <div className={style.wrapper}>
      <Title level={2}>{t('shell:userProfile.title')}</Title>
      <Spin spinning={loadingInvoices} />
      <div className={style.purchaseList}>
        {payments &&
          payments.Payments &&
          payments.Payments.items &&
          payments.Payments.items.map((payment: PayformPaymentResponseDto) => (
            <PurchaseItem key={payment.id} payment={payment} cart={cartItems && cartItems.CartItemsByIds} loading={loadingCartItems} />
          ))}
      </div>
    </div>
  );
};

export default PurchaseList;
