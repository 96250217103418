import React from 'react';
import style from './style.module.scss';
import { Layouts } from '../../../components/components';
import { SitePageContextProjectBlocks } from '../../../graphql-types';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';

interface ProjectSmallListProps {
  data: SitePageContextProjectBlocks | null | undefined;
}

const ProjectSmallList: React.FC<ProjectSmallListProps> = ({ data }) => {
  const { Container } = Layouts;
  return (
    <Container className={style.listContainer}>
      <div className={style.list}>
        {data?.ProjectSmallItem &&
          data.ProjectSmallItem.length !== 0 &&
          data.ProjectSmallItem.map((el, index) => (
            <div className={style.item} key={el?.id || index}>
              <div className={style.left}>
                <ReactMarkdown transformImageUri={img => `${process.env.STRAPI_API_URL}${img}`} children={el?.leftDescription || ''} remarkPlugins={[remarkGfm]} />
              </div>
              <div className={style.dot}>
                <div className={style.firstCircle}>
                  <div className={style.secondCircle} />
                </div>
              </div>
              <div className={style.right}>
                <ReactMarkdown transformImageUri={img => `${process.env.STRAPI_API_URL}${img}`} children={el?.rightDescription || ''} remarkPlugins={[remarkGfm]} />
              </div>
            </div>
          ))}
      </div>
    </Container>
  );
};

export default ProjectSmallList;
