import BulletList from './BulletList/BulletList';
import ProjectAuthor from './ProjectAuthor/ProjectAuthor';
import NumberedList from './NumberedList/NumberedList';
import PaymentModule from './PaymentModule/PaymentModule';
import ProjectItemsList from './ProjectItemsList/ProjectItemsList';
import ProjectSelect from './ProjectSelect/ProjectSelect';
import ProjectSmallList from './ProjectSmallList/ProjectSmallList';
import ProjectPictureWithText from './ProjectPictureWithText/ProjectPictureWithText';
import BlockWithCircleImage from './BlockWithCircleImage/BlockWithCircleImage';

export default {
  BulletList,
  ProjectAuthor,
  NumberedList,
  PaymentModule,
  ProjectItemsList,
  ProjectSelect,
  ProjectSmallList,
  ProjectPictureWithText,
  BlockWithCircleImage,
};
