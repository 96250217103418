import React from 'react';
import style from './style.module.scss';
import { StrapiPagesBlocks } from '../../../../graphql-types';
import { chunkArray } from '../../../../lib/utils';

interface PublicAcceptanceProps {
  data: StrapiPagesBlocks | null | undefined;
}

const PublicAcceptance: React.FC<PublicAcceptanceProps> = ({ data }) => (
  <div className={style.publicWrapper}>
    <h2 className={style.publicTitle}>{data?.bottomBlockTitle}</h2>
    <div className={style.publicList}>
      {data?.markedElem &&
        data.markedElem.length !== 0 &&
        chunkArray(data?.markedElem || [], 2).map((array, index) => (
          <div className={style.publicItem} key={index}>
            {array.map((elem, index) => (
              <div className={style.listItem} key={elem?.id || index}>
                {elem?.label}
              </div>
            ))}
          </div>
        ))}
    </div>
  </div>
);

export default PublicAcceptance;
