import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import UserInfo from './UserInfo';
import PurchaseList from './PurchaseList';
import { Spin } from 'antd';
import css from './style.module.scss';

const UserProfileContent: React.FC = () => {
  const { user, logout, isLoading } = useAuth0();
  return (
    <>
      {!isLoading ? (
        <>
          <UserInfo data={user} logout={logout} />
          <PurchaseList />
        </>
      ) : (
        <div className={css.spinContainer}>
          <Spin />
        </div>
      )}
    </>
  );
};

export default UserProfileContent;
