import gql from 'graphql-tag';

export const PAYMENT_PROVIDER_CURRENCY_BY_COUNTRY_ABV_REQUEST = (countryCode: string) => {
  return gql`
  {
    PaymentProviderByCountryAbv(countryAbv: "${countryCode}") {
      id
      currencies {
        id
        currency
      }
    }
  }
  `;
};

export const PAYMENT_PROVIDER_DATA_BY_COUNTRY_ABV_REQUEST = (countryCode: string) => {
  return gql`
  {
    PaymentProviderByCountryAbv(countryAbv: "${countryCode}") {
      id
      name
      type
      currencies {
        id
        currency
      }
    }
  }
  `;
};

export const PAYMENTS_BY_EMAIL = (email: string) => {
  return gql`
    {
        Payments(participant: "${email}", status: "COMPLETED") {
          items {
            id
            paidAt
            total
            currency
            invoiceId
            cart {
              cartItemId
            }
          }
        }
    }
  `;
};
