import React, { useEffect, useState } from 'react';
import style from './style.module.scss';
import { StrapiArticles } from '../../../../graphql-types';
import ReactMarkdown from 'react-markdown';
import { Link } from 'gatsby';
import { Button } from '../../../../components/components';
import { FontWeight } from '../../../../lib/constants/enums';
import { pathTo } from '../../../../lib/utils';
import remarkGfm from 'remark-gfm';

interface LatestArticlesProps {
  art: StrapiArticles;
  slug?: string;
}

const Article: React.FC<LatestArticlesProps> = ({ art, slug }) => {
  const [imageUrl, setImageUrl] = useState('');
  useEffect(() => {
    const domain = typeof window !== 'undefined' ? window.location.protocol + '//' + window.location.hostname : '';
    setImageUrl(art.picture?.url ? `${process.env.STRAPI_API_URL}${art.picture?.url}` : `${domain}${art.picture?.publicURL}`);
  }, []);

  return (
    <div className={style.artCart}>
      <div className={style.artImage}>
        {art.picture && <img src={imageUrl} alt="" />}
        <span className={style.artDate}>{new Date(art.published_at).toLocaleString('ru', { day: 'numeric', month: 'long' })}</span>
      </div>
      <div className={style.artContent}>
        <h4 className={style.artTitle}>{art.title}</h4>
        <div className={style.artDescription}>
          <ReactMarkdown transformImageUri={img => `${process.env.STRAPI_API_URL}${img}`} children={art.content || ''} remarkPlugins={[remarkGfm]} />
        </div>
        <Link className={style.artLink} to={pathTo(`blog/${slug}/${art.routeName}`)}>
          <Button title={'Читать подробнее'} border={true} fontWeight={FontWeight.medium} fontSize={14} />
        </Link>
      </div>
    </div>
  );
};

export default Article;
