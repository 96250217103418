import React from 'react';
import style from './style.module.scss';
import { Layouts, Button } from '../../../components/components';
import { StrapiPagesBlocks, SitePageContextProjectTheme } from '../../../graphql-types';
import { colors } from '../../../styles/variables';
import ReactMarkdown from 'react-markdown';
import ColorService from '../../../lib/services/colorService';
import { FontWeight, CartItemsType } from '../../../lib/constants/enums';
import remarkGfm from 'remark-gfm';

interface CartListProps {
  data: StrapiPagesBlocks | null | undefined;
  generalTheme?: SitePageContextProjectTheme | null | undefined;
  type?: CartItemsType;
}

const CartList: React.FC<CartListProps> = ({ data, generalTheme, type = CartItemsType.default }) => {
  const { Container, LayoutBG, AdaptiveLayout } = Layouts;
  const colorService = new ColorService();

  const renderBtn = (fontSize: number, title?: string) => (
    <Button
      title={title || 'Читать подробнее'}
      border={false}
      bgColor={
        (generalTheme?.firstOptionalColor &&
          generalTheme?.secondOptionalColor &&
          colorService.generateGradient(generalTheme.firstOptionalColor, generalTheme?.secondOptionalColor, 180)) ||
        colors.defaultGradientButton
      }
      shadowColor={
        (generalTheme?.firstOptionalColor && colorService.generateShadow('0px 14px 20px', generalTheme.firstOptionalColor, 0.8)) ||
        colors.greenShadow
      }
      from={(generalTheme?.firstOptionalColor && generalTheme.firstOptionalColor) || colors.yellow}
      to={(generalTheme?.secondOptionalColor && generalTheme.secondOptionalColor) || colors.green}
      fontSize={fontSize}
      fontWeight={FontWeight.bold}
      uppercase={true}
    />
  );

  return (
    <LayoutBG
      background={
        data?.theme && data.theme.backgroundImage
          ? `url(${process.env.STRAPI_API_URL}${data.theme.backgroundImage?.formats?.large?.url ||
              data.theme.backgroundImage?.url}) center top / cover no-repeat`
          : 'none'
      }
      className={style.cartListWrap}
    >
      <Container className={style.cartlistContainer}>
        <h3 className={style.cartlistTitle} style={{ color: (data?.theme && data.theme.textColor) || colors.black }}>
          {data?.title}
        </h3>
        {data?.description && (
          <div style={{ color: (data?.theme && data.theme.textColor) || colors.black }} className={style.cartListDescription}>
            <ReactMarkdown transformImageUri={img => `${process.env.STRAPI_API_URL}${img}`} children={data.description} remarkPlugins={[remarkGfm]} />
          </div>
        )}
        {type === CartItemsType.default ? (
          <div className={style.cartListFlex}>
            {data?.cartItem &&
              data.cartItem.map((item, index) => (
                <div className={style.item} key={index}>
                  <img src={`${process.env.STRAPI_API_URL}${item?.picture?.url}`} alt="" />
                  <div className={style.itemInfo}>
                    <h4>{item?.title}</h4>
                    {item?.description && (
                      <div className={style.itemDescription}>
                        <ReactMarkdown transformImageUri={img => `${process.env.STRAPI_API_URL}${img}`} children={item.description} remarkPlugins={[remarkGfm]} />
                      </div>
                    )}
                    <a className={style.itemLink} href={item?.link || '#'}>
                      <AdaptiveLayout onDesktop onTablet>
                        {renderBtn(21, item?.btnTitle || '')}
                      </AdaptiveLayout>
                      <AdaptiveLayout onMobile>{renderBtn(16, item?.btnTitle || '')}</AdaptiveLayout>
                    </a>
                  </div>
                </div>
              ))}
          </div>
        ) : (
          <div className={style.itemsOnLineFlex}>
            {data?.aboutItem &&
              data.aboutItem.map((item, index) => (
                <div className={style.itemonLine} key={item?.id || index}>
                  <img src={`${process.env.STRAPI_API_URL}${item?.picture?.url}`} alt="" />
                  <div className={style.itemonLineDescription}>
                    <ReactMarkdown transformImageUri={img => `${process.env.STRAPI_API_URL}${img}`} children={item?.description || ''} remarkPlugins={[remarkGfm]} />
                  </div>
                </div>
              ))}
          </div>
        )}
        {type === CartItemsType.itemsOnLine && data?.CTA && data.CTA.buttonTitle && (
          <a className={style.itemsOnLineBtn} href={data.CTA.btnLink || '#'}>
            <AdaptiveLayout onDesktop onTablet>
              {renderBtn(21, data.CTA.buttonTitle)}
            </AdaptiveLayout>
            <AdaptiveLayout onMobile>{renderBtn(16, data.CTA.buttonTitle)}</AdaptiveLayout>
          </a>
        )}
      </Container>
    </LayoutBG>
  );
};

export default CartList;
