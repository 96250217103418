import React from 'react';
import { Link } from 'gatsby';
import style from './style.module.scss';
import { Button, Layouts } from '../../../../components/components';
import { SitePageContextProjectPageInfo, SitePageContextProjectTheme } from '../../../../graphql-types';
import { pathTo } from '../../../../lib/utils';
import { FontWeight } from '../../../../lib/constants/enums';
import { colors } from '../../../../styles/variables';
import ColorService from '../../../../lib/services/colorService';
import { Cart } from './style';

interface ProjectCartProps {
  data: SitePageContextProjectPageInfo | null | undefined;
  slug: string;
  theme?: SitePageContextProjectTheme | null | undefined;
}

const ProjectCart: React.FC<ProjectCartProps> = ({ data, slug, theme }) => {
  const colorService = new ColorService();
  const { AdaptiveLayout } = Layouts;
  const renderBtn = (fontSize = 21, border = false) => (
    <Button
      bgColor={
        (theme?.firstOptionalColor &&
          theme?.secondOptionalColor &&
          colorService.generateGradient(theme.firstOptionalColor, theme?.secondOptionalColor, 180)) ||
        colors.defaultGradientButton
      }
      shadowColor={
        (theme?.firstOptionalColor && colorService.generateShadow('0px 14px 20px', theme.firstOptionalColor, 0.8)) || colors.greenShadow
      }
      from={(theme?.firstOptionalColor && theme.firstOptionalColor) || colors.yellow}
      to={(theme?.secondOptionalColor && theme.secondOptionalColor) || colors.green}
      title="Узнать подробнее"
      uppercase
      fontWeight={FontWeight.bold}
      fontSize={fontSize}
      border={border}
    />
  );

  const btnType = () => {
    const regExp = new RegExp('http', 'gi');

    return slug.match(regExp) === null ? (
      <Link className={style.cartBtn} to={pathTo(`/project/${slug}`)}>
        <AdaptiveLayout onDesktop onTablet>
          {renderBtn()}
        </AdaptiveLayout>
        <AdaptiveLayout onMobile>{renderBtn(14, true)}</AdaptiveLayout>
      </Link>
    ) : (
      <a className={style.cartBtn} href={slug} target="_blank" rel="noreferrer">
        <AdaptiveLayout onDesktop onTablet>
          {renderBtn()}
        </AdaptiveLayout>
        <AdaptiveLayout onMobile>{renderBtn(14, true)}</AdaptiveLayout>
      </a>
    );
  };

  return (
    <Cart
      shadowColor={
        (theme?.firstOptionalColor && colorService.generateShadow('0px 5px 10px', theme.firstOptionalColor, 0.3)) || colors.greenShadow
      }
      className={style.cartWrap}
    >
      <img src={`${process.env.STRAPI_API_URL}${data?.picture?.url}`} className={style.cartImage} alt="" />
      {btnType()}
      <h3 className={style.cartTitle}>{data?.title}</h3>
      <div className={style.cartInfo}>
        <div className={style.duration}>
          Длительность: <strong>{data?.duration}</strong>
        </div>
        <div className={style.date}>
          Дата старта: <strong>{data?.date}</strong>
        </div>
      </div>
    </Cart>
  );
};

export default ProjectCart;
