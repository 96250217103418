import React from 'react';
import style from './style.module.scss';
import { Layouts } from '../../../components/components';
import PublicAcceptance from './PublicAcceptance/PublicAcceptance';
import { StrapiPagesBlocks } from '../../../graphql-types';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';

interface AuthorProps {
  data: StrapiPagesBlocks | null | undefined;
}

const Author: React.FC<AuthorProps> = ({ data }) => {
  const { LayoutBG, Container } = Layouts;
  return (
    <LayoutBG className={style.authorBg}>
      <Container className={style.authorContainer}>
        <div className={style.authorWrapper}>
          <div className={style.authorList}>
            <div className={style.authorItem}>
              <h3 className={style.authorTitle}>{data?.title}</h3>
              <div className={style.authorDescription}>
                <ReactMarkdown transformImageUri={img => `${process.env.STRAPI_API_URL}${img}`} children={data?.description || ''} remarkPlugins={[remarkGfm]} />
              </div>
            </div>
            <div className={style.authorItem}>
              <img className={style.authorImage} src={`${process.env.STRAPI_API_URL}/uploads/author_bg_947fdee05a.png`} />
            </div>
          </div>
          <PublicAcceptance data={data} />
        </div>
      </Container>
    </LayoutBG>
  );
};

export default Author;
