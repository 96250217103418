/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useRef, useState, FC, ReactNode } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { Location, WindowLocation } from '@reach/router';
import { useTranslation } from 'react-i18next';
import { LoadingOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Card, Spin, Steps } from 'antd';
import { FormComponentProps } from '@ant-design/compatible/es/form';
import * as ISOCountries from 'i18n-iso-countries';
import GQL from '../../../graphql-types';
import i18n from '../../../i18n';
import { usePrevious } from '../../../lib/hooks/usePrevious';
import {
  ClearOrderActionCreator,
  ReadOrderActionCreator,
  SaveOrderFieldsAction,
  SaveOrderFieldsActionCreator,
  CreateOrderActionCreator,
} from '../../../redux/order/order.actions';
import { CartItem, Order, ShippingMethod } from '../../../lib/common-interfaces';
import { RootModel } from '../../../redux/store';
import { payOnline } from '../../../lib/constants';
import { CurrencySelector } from '../../../redux/currency/currency.selector';
import { useRates } from '../../../lib/hooks/rates';
import { useFulfillmentRate } from '../../../lib/hooks/useFulfillmentRate';
import { useLazyQuery } from '@apollo/react-hooks';
import { PAYMENT_PROVIDER_DATA_BY_COUNTRY_ABV_REQUEST } from '../../../api/api-apollo/query/payment';
import { initialStatus } from '../../../lib/constants/data';
import { StepStatus } from '../../../lib/constants/enums';
import { CheckoutFormStep1 } from './Steps/step1-form';
import { CheckoutFormStep2 } from './Steps/step2-form';
import { CheckoutFormStep3 } from './Steps/step3-form';
import { maxStep, enumNormalizer, formItemLayout } from '../../../redux/utils/checkout';
import { CartCheckoutSelector } from '../../../redux/cart/cart.selector';
import { TFunction } from 'i18next';

// eslint-disable-next-line @typescript-eslint/no-var-requires
ISOCountries.registerLocale(require('i18n-iso-countries/langs/en.json'));
// eslint-disable-next-line @typescript-eslint/no-var-requires
ISOCountries.registerLocale(require('i18n-iso-countries/langs/ru.json'));
import css from './checkoutform.module.scss';

export interface CheckoutFormComponentProps extends FormComponentProps {
  products: GQL.ContentfulProduct[];
  cart: CartItem[];
}

interface IStepsItem {
  key: number;
  title: string;
  status: StepStatus;
  description: ReactNode;
}

interface ICheckoutFormProps extends FormComponentProps {
  t: TFunction;
  setStep: (value: number) => void;
}

const CheckoutFormComponent: FC<CheckoutFormComponentProps> = ({ form }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { validateFields } = form;
  const { Step } = Steps;

  const { cart, order, countryCode } = useSelector(CartCheckoutSelector);
  const { currency } = useSelector(CurrencySelector);

  const { data, loading: fulFillmentLoadingStatus } = useFulfillmentRate(countryCode || '', cart, currency);
  const [currencyRequest, { data: PaymentProviders, loading: paymentProvidersLoadingStatus }] = useLazyQuery(
    PAYMENT_PROVIDER_DATA_BY_COUNTRY_ABV_REQUEST(countryCode || '')
  );

  const [location, updateLocation] = useState<WindowLocation | undefined>();
  const [currentStep, updateCurrentStep] = useState<number>(0);
  const [stepStatus, updateStepStatus] = useState(initialStatus);
  const isVertical = useRef(true);

  const prevLocation = usePrevious(location);
  const prevStep = usePrevious(currentStep);
  const { convert, rates } = useRates();

  const validateStep = (step: number) => {
    let fieldSet: string[] = [];
    switch (step) {
      case 0:
        fieldSet = ['firstName', 'lastName', 'email', 'country', 'address', 'postalCode', 'phone'];
        break;
      case 1:
        fieldSet = ['shippingMethod'];
        break;
      case 2:
        fieldSet = ['paymentMethod'];
        break;
      default:
        break;
    }
    validateFields(fieldSet, (errors, values) => {
      let status: StepStatus;

      if (!errors && values) {
        status = StepStatus.finish;
        if (step <= maxStep) updateCurrentStep(step + 1);
      } else {
        status = StepStatus.error;
      }
      updateStepStatus(prevState => {
        return { ...prevState, [step]: status };
      });
    });
  };

  useEffect(() => {
    if (countryCode) currencyRequest();
  }, [countryCode]);

  useEffect(() => {
    if (prevStep !== undefined) validateStep(prevStep);
  }, [currentStep]);

  const validateAndSubmitForm = () => {
    form.validateFields((errors, values) => {
      if (!errors) {
        const { countryCode: countryCodeFromForm, paymentMethod, shippingMethod, phone, country, ...participantPayerData } = values;
        const orderToCreateInvoice = {
          participant: {
            ...participantPayerData,
            country: countryCode,
            phone,
          },
          payer: {
            ...participantPayerData,
            country: countryCode,
          },
          cart: cart.map(it => ({
            quantity: it.quantity,
            productId: Number(it.product.id),
            item: {
              optionIds: !!it.selectedOptionIds ? Object.values(Number(it.selectedOptionIds)) : [],
            },
            type: enumNormalizer(it.product.inDetail.type),
          })),
          products: cart,
          shippingMethod,
          paymentMethod,
        };

        dispatch(CreateOrderActionCreator(orderToCreateInvoice));
      }
    });
  };

  useEffect(() => {
    if (Object.values(stepStatus).every(status => status === StepStatus.finish)) {
      validateAndSubmitForm();
    }
  }, [stepStatus]);

  const setStep = (step: number) => {
    if (order) dispatch(ClearOrderActionCreator());
    updateStepStatus(prevState => {
      return { ...prevState, [step]: StepStatus.process };
    });
    updateCurrentStep(step);
  };

  const handleParams = (l: WindowLocation) => {
    if (l.search) {
      const url = new URL(l.href);
      const hashKey = url.searchParams.get('key');
      if (hashKey) {
        dispatch(ReadOrderActionCreator({ key: hashKey }));
      }
    }
  };

  useEffect(() => {
    if (location && location !== prevLocation) {
      handleParams(location as WindowLocation);
    }
  }, [location]);

  useEffect(() => {
    dispatch(ClearOrderActionCreator());
  }, []);

  const checkoutFormProps: ICheckoutFormProps = {
    form,
    t,
    setStep,
  };

  const stepItems: IStepsItem[] = [
    {
      key: 0,
      title: t('checkout:form.step1.title'),
      status: stepStatus[0],
      description: <CheckoutFormStep1 {...checkoutFormProps} countryCode={countryCode} />,
    },
    {
      key: 1,
      title: t('checkout:form.step2.title'),
      status: stepStatus[1],
      description: (
        <Card className={css.card}>
          <Spin spinning={fulFillmentLoadingStatus} indicator={<LoadingOutlined />}>
            {data && (
              <CheckoutFormStep2
                {...checkoutFormProps}
                convert={convert}
                rates={rates}
                currency={currency}
                calculateFulFillmentRateData={data.CalculateFulFillmentRate}
                validateStep={validateStep}
              />
            )}
          </Spin>
        </Card>
      ),
    },
    {
      key: 2,
      title: t('checkout:form.step3.title'),
      status: stepStatus[2],
      description: (
        <Card className={css.card}>
          <Spin spinning={paymentProvidersLoadingStatus} indicator={<LoadingOutlined />}>
            {PaymentProviders && (
              <CheckoutFormStep3
                {...checkoutFormProps}
                validateStep={validateStep}
                PaymentProviderByCountryAbv={PaymentProviders.PaymentProviderByCountryAbv}
              />
            )}
          </Spin>
        </Card>
      ),
    },
  ];

  return (
    <Location>
      {({ location: loc }) => {
        if (location !== loc) {
          updateLocation(loc);
        }
        return (
          <Form {...formItemLayout} hideRequiredMark>
            <Steps direction={isVertical ? 'vertical' : 'horizontal'} current={currentStep}>
              {stepItems.map(step => (
                <Step {...step} />
              ))}
            </Steps>
          </Form>
        );
      }}
    </Location>
  );
};

interface MapStateToPropsProps {
  cart: CartItem[];
  manager?: string;
  shippingSelected?: ShippingMethod;
}

const mapStateToProps = (state: RootModel) => ({
  cart: state.cart.cart,
  manager: state.order.manager,
  shippingSelected: state.shipping.selected,
});

// TODO remove this connect because now it works with graphql
export const CheckoutForm = connect(mapStateToProps, { SaveOrderFieldsActionCreator })(
  Form.create<FormComponentProps & MapStateToPropsProps & SaveOrderFieldsAction>({
    onFieldsChange(props, _changedValues, values) {
      // Save order withut validation for sharing to customer by link
      const orderFieldsToSave: Partial<Order> = {
        user: {
          firstName: values.firstName.value,
          lastName: values.lastName.value,
          phone: values.phone.value, // phonePrefix.concat(values.phone),
          email: values.email.value,
          country: values.country.value,
          countryCode: ISOCountries.getAlpha2Code(values.country.value, i18n.language),
          city: values.city.value,
          address: values.address.value,
          postalCode: values.postalCode.value,
        },
        products: props.cart,
        shippingMethod: props.shippingSelected as ShippingMethod,
        paymentMethod: { name: i18n.t(`checkout:payment.${payOnline.name}`), _id: payOnline._id, icon: payOnline.icon },
        manager: props.manager,
      };
      props.SaveOrderFieldsActionCreator(orderFieldsToSave);
    },
  })(CheckoutFormComponent)
);
