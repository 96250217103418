import React from 'react';
import style from './style.module.scss';
import { Layouts, Button } from '../../../components/components';
import Image from '../../../assets/images/list.png';
import { FontWeight } from '../../../lib/constants/enums';

const Subsplash: React.FC = () => {
  const { Container } = Layouts;
  return (
    <Container className={style.container}>
      <div className={style.list}>
        <div className={style.listItem}>
          <h3 className={style.leftItemText}>Призыв к действию чтобы оставил заявку</h3>
        </div>
        <div className={style.subsplashImageWrapper}>
          <img className={style.subsplashImage} src={Image} alt="" />
        </div>
        <div className={style.listItem}>
          <Button fontWeight={FontWeight.bold} border uppercase title={'ХОЧУ ТРЕНИРОВКИ'} />
        </div>
      </div>
    </Container>
  );
};

export default Subsplash;
