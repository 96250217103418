import React from 'react';
import style from './style.module.scss';
import { Layouts, Button } from '../../../components/components';
import { SitePageContextProjectBlocks, SitePageContextProjectTheme } from '../../../graphql-types';
import { colors } from '../../../styles/variables';
import ReactMarkdown from 'react-markdown';
import { FontWeight } from '../../../lib/constants/enums';
import ColorService from '../../../lib/services/colorService';
import remarkGfm from 'remark-gfm';

interface ProjectPictureWithTextProps {
  data: SitePageContextProjectBlocks | null | undefined;
  generalTheme?: SitePageContextProjectTheme | null | undefined;
}

const ProjectPictureWithText: React.FC<ProjectPictureWithTextProps> = ({ data, generalTheme }) => {
  const { Container, LayoutBG, AdaptiveLayout } = Layouts;
  const colorService = new ColorService();
  return (
    <LayoutBG
      background={
        (data?.theme &&
          data.theme?.backgroundImage &&
          `url(${process.env.STRAPI_API_URL}${data.theme.backgroundImage.formats?.medium?.url}) center center / cover no-repeat`) ||
        'none'
      }
      className={style.pictureWithTextWrapper}
    >
      <Container className={style.pictureWithTextContainer}>
        <div className={style.pictureWithTextList}>
          {data?.picture && <img src={`${process.env.STRAPI_API_URL}${data.picture.formats?.medium?.url}`} alt="" />}
          <div className={style.contentItem}>
            <h4 style={{ color: generalTheme?.mainColor || colors.black }} className={style.contentTitle}>
              {data?.title}
            </h4>
            <div className={style.contentDescription}>
              <ReactMarkdown transformImageUri={img => `${process.env.STRAPI_API_URL}${img}`} children={data?.description || ''} remarkPlugins={[remarkGfm]} />
            </div>
            {data?.CTA && data.CTA.buttonTitle && (
              <a href={data.CTA.btnLink || '#'} className={style.contentLink}>
                <AdaptiveLayout onDesktop onTablet>
                  <Button
                    bgColor={
                      (generalTheme?.firstOptionalColor &&
                        generalTheme?.secondOptionalColor &&
                        colorService.generateGradient(generalTheme.firstOptionalColor, generalTheme?.secondOptionalColor, 180)) ||
                      colors.defaultGradientButton
                    }
                    shadowColor={
                      (generalTheme?.firstOptionalColor &&
                        colorService.generateShadow('0px 14px 20px', generalTheme.firstOptionalColor, 0.8)) ||
                      colors.greenShadow
                    }
                    from={(generalTheme?.firstOptionalColor && generalTheme.firstOptionalColor) || colors.yellow}
                    to={(generalTheme?.secondOptionalColor && generalTheme.secondOptionalColor) || colors.green}
                    title={data.CTA.buttonTitle}
                    border={(data.CTA?.extraBorder && data.CTA.extraBorder) || false}
                    fontWeight={FontWeight.bold}
                    uppercase={true}
                    fontSize={23}
                  />
                </AdaptiveLayout>
                <AdaptiveLayout onMobile>
                  <Button
                    bgColor={
                      (generalTheme?.firstOptionalColor &&
                        generalTheme?.secondOptionalColor &&
                        colorService.generateGradient(generalTheme.firstOptionalColor, generalTheme?.secondOptionalColor, 180)) ||
                      colors.defaultGradientButton
                    }
                    shadowColor={
                      (generalTheme?.firstOptionalColor &&
                        colorService.generateShadow('0px 14px 20px', generalTheme.firstOptionalColor, 0.8)) ||
                      colors.greenShadow
                    }
                    from={(generalTheme?.firstOptionalColor && generalTheme.firstOptionalColor) || colors.yellow}
                    to={(generalTheme?.secondOptionalColor && generalTheme.secondOptionalColor) || colors.green}
                    title={data.CTA.buttonTitle}
                    border={(data.CTA?.extraBorder && data.CTA.extraBorder) || false}
                    fontWeight={FontWeight.bold}
                    uppercase={true}
                    fontSize={18}
                  />
                </AdaptiveLayout>
              </a>
            )}
          </div>
        </div>
      </Container>
    </LayoutBG>
  );
};

export default ProjectPictureWithText;
